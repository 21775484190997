import { emergency } from './emergency'
import { selfHelp } from './self-help'
import { survey } from './survey'
import { assets } from './assets'
import { mapStyle } from './map'
import { serviceChangeFields } from '../shared/search'

const config = {
  id: 'default',

  options: {
    categoryMode: 'multiple',
    combineMode: 'filters',
    defaultCategoryTitle: 'All Services',
    defaultCategorySuffix: 'Services',
    freshdeskTitle: 'My Services',
  },

  analytics: {
    app: 'myservices',
    plugins: [
      // {
      //   provider: 'google-analytics',
      //   // trackingId: 'UA-188798033-1', // AMS Test
      //   trackingId: 'UA-147876591-5',
      //   // customScriptSrc: 'https://www.google-analytics.com/analytics_debug.js',
      //   siteSpeedSampleRate: 100,
      // },
      {
        provider: 'cie-elastic',
        index: 'prod-ams-mindcare-analytics',
        // debug: true,
      },
    ],
    googleAnalytics: {
      containerId: 'GTM-M3FBDZL',
    },
  },

  meta: {
    templates: {
      titlePrefix: 'My Services Community Directory',
      title: '',
      description:
        'My Services promotes improved mental health and wellbeing by empowering our community through education, information and connectivity to achieve our Vision of a community that is mentally well, resilient and stigma free.',
    },
  },

  routes: [
    {
      path: '/',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'home',
      },
    },
    {
      path: '/search',
      component: 'Search',
      search: true,
      meta: {
        templates: {
          title: 'Search for a Service',
        },
      },
    },
    {
      path: '/survey/start/:pathway',
      exact: true,
      component: 'SurveyRedirect',
    },
    {
      path: '/survey',
      component: 'Survey',
      container: 'ContentPage',
      meta: {
        templates: {
          title: 'Guided Support Search',
        },
      },
    },
    {
      path: '/emergency',
      component: 'EmergencyBlock',
      container: 'ContentPage',
      props: {
        data: emergency,
      },
      meta: {
        templates: {
          title: 'Emergency Help',
        },
      },
    },
    {
      path: '/self-help-resources',
      component: 'SelfHelpBlock',
      container: 'ContentPage',
      props: {
        data: selfHelp,
      },
      meta: {
        templates: {
          title: 'Self Help Resources',
        },
      },
    },
    {
      path: '/support',
      component: 'Support',
      container: 'ContentPage',
      props: {
        contentId: 'support',
      },
      meta: {
        templates: {
          title: 'Site Support',
        },
      },
    },
    {
      path: '/location/:lat/:lng',
      component: 'LocationRedirect',
      props: {
        redirectTo: '/',
      },
    },
    {
      path: '/about',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'about',
      },
      meta: {
        templates: {
          title: 'About Us',
        },
      },
      pathConfig: {
        about: {},
      },
    },
    {
      path: '/404',
      exact: true,
      component: 'NotFound',
      container: 'ContentPage',
      containerProps: {
        backButton: false,
      },
      meta: {
        templates: {
          title: '404 Not Found',
        },
      },
    },
    {
      path: '*',
      component: 'Redirect',
      props: {
        to: '/404',
      },
    },
  ],

  ui: {
    appBar: {
      elevation: 0,
      color: 'secondary',
      groups: [
        {
          align: 'left',
          items: [
            {
              component: 'MenuButton',
              props: {
                sx: { px: 2 },
              },
            },
            {
              component: 'AppBarTitle',
              props: {
                // logo: 'logoWhite',
                // // disableTextTitle: true,
                // disableLogo: true,
                to: '/',
              },
            },
          ],
        },
        {
          align: 'fill',
          items: [],
        },
        {
          align: 'right',
          items: [
            {
              component: 'AppBarButton',
              media: [{ query: 'up', breakpoints: ['sm'], prop: 'render' }],
              props: {
                icon: 'fas fa-sign-in',
                label: 'Sign In',
                href: 'https://www.mycommunitydirectory.com.au/Manage/Dashboard',
                target: '_blank',
                variant: 'text',
                color: 'inherit',
                disableElevation: true,
              },
            },
          ],
        },
      ],
    },

    homeButtons: {
      props: {
        xs: 12,
        md: 6,
      },
      buttons: [
        {
          id: 'immediate-help',
          label: 'Immediate Help',
          icon: 'fas fa-medkit',
          route: '/emergency',
        },
        {
          id: 'search-tile',
          label: 'Search for a Service',
          icon: 'fa-solid fa-magnifying-glass',
          route: '/search',
        },
        {
          id: 'alcohol-drug-support',
          label: 'Alcohol or Drug Support and Advice',
          icon: 'fas fa-wine-bottle',
          route: '/survey/start/alcoholdrug',
        },
        {
          id: 'mental-health-support',
          label: 'Mental Health Support and Advice',
          icon: 'fas fa-comment-medical',
          route: '/survey/start/mental',
        },
      ],
    },

    footer: {
      sections: [
        {
          theme: 'variants.footer',
          blocks: [
            {
              component: 'CieBlock',
              gridProps: { xs: 12, md: 4 },
            },
            {
              title: {
                label: 'Menu',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'navigation',
                hide: 'footer',
              },
            },
            {
              title: {
                label: 'Contact',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'contact',
                hide: 'footer',
              },
            },
          ],
        },
      ],
    },

    acknowledgements: {
      // isDisplayed: true,
    },

    menus: {
      navigation: {
        items: [
          {
            id: 'home',
            label: 'Home',
            icon: 'fas fa-home-alt',
            route: '/',
          },
          {
            id: 'emergency',
            label: 'Immediate Help',
            icon: 'fas fa-plus-square',
            route: '/emergency',
          },
          // {
          //   id: 'self',
          //   label: 'Guided Support Search',
          //   icon: 'fas fa-clipboard-list-check',
          //   route: '/survey/start/self',
          // },
          {
            id: 'search',
            label: 'Search for a Service',
            icon: 'fa-solid fa-magnifying-glass',
            route: '/search',
          },
          {
            id: 'self-help-resources',
            label: 'Self Help Resources',
            icon: 'fas fa-user-check',
            route: '/self-help-resources',
          },
          {
            id: 'about',
            label: 'About Us',
            icon: 'fas fa-question-circle',
            route: '/about',
          },
          {
            type: 'divider',
            hide: ['footer'],
          },
          {
            id: 'support',
            label: 'Site Support',
            icon: 'fa-solid fa-headset',
            route: '/support',
          },
          {
            id: 'signIn',
            label: 'Sign In',
            icon: 'fas fa-sign-in',
            href: 'https://www.mycommunitydirectory.com.au/Manage/Dashboard',
            hide: ['footer'],
          },
          {
            id: 'reset',
            label: 'Clear Settings & Restart',
            icon: 'fas fa-undo',
            function: 'reset',
            hide: ['footer'],
          },
        ],
      },
      contact: {
        items: [
          {
            id: 'web',
            label: 'myservices.org.au',
            icon: 'fa-solid fa-globe',
            href: 'https://myservices.org.au/',
          },
          {
            id: 'register',
            label: 'Join for Free',
            icon: 'fas fa-solid fa-user-plus',
            href: 'https://www.mycommunitydirectory.com.au/Register',
          },
          {
            id: 'email',
            label: 'MyServices@mhc.wa.gov.au',
            icon: 'fas fa-envelope',
            href: 'mailto:MyServices@mhc.wa.gov.au',
          },
          {
            id: 'address',
            label: 'Level 1, 1 Nash Street\nPerth, WA 6000',
            icon: 'fas fa-map-marker-alt',
            href: 'https://goo.gl/maps/53xbb2e17nTSJqEb7',
          },
        ],
      },
    },

    search: {
      desktop: {
        homeButton: {
          show: true,
          path: '/',
        },
        paramsDrawer: {
          breakpoint: 'lg',
        },
        disableParams: true,
      },

      tabs: [
        {
          id: 'services',
          title: 'Services',
          icon: 'fas fa-building',
          component: 'SearchResults',
          map: true,
          options: {
            tabHint: true,
            search: 'services',
          },
        },
        {
          id: 'favourites',
          title: 'Favourites',
          icon: 'fas fa-star',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'favourites',
          },
        },
      ],

      map: {
        style: mapStyle,
      },
      tagWAPHA: [1213, 1212, 1215, 1214, 1210],
      parameters: {
        query: {
          id: 'query',
          type: 'query',
          label: 'Search',
          icon: 'fa-solid fa-magnifying-glass',
        },
        location: {
          id: 'location',
          type: 'location',
          label: 'Location',
          icon: 'fa-solid fa-location-dot',
        },
        radius: {
          id: 'radius',
          type: 'radius',
          label: 'Radius',
          icon: 'fa-solid fa-person-running',
        },
        categories: {
          id: 'categories',
          type: 'categoryButtons',
          label: 'Categories',
          icon: 'fa-solid fa-bars-staggered',
        },
        age: {
          id: 'age',
          type: 'select',
          label: 'Age',
          options: ['0 - 15', '16 - 25', '26 - 40', '41 - 64', '65+'],
        },
        gender: {
          id: 'gender',
          type: 'select',
          label: 'Gender',
          options: ['Male', 'Female', 'Intersex', 'Transgender'],
        },
      },

      parameterGroups: {
        desktop: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'categories' },
            { param: 'age' },
            { param: 'gender' },
          ],
        },
        mobileSearch: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'age' },
            { param: 'gender' },
          ],
        },
        mobileCategories: {
          controls: [{ param: 'categories' }],
        },
      },

      resultsList: {
        default: {
          sections: [
            // {
            //   type: 'alertBlock',
            //   items: [
            //     { component: 'CovidAlert' },
            //   ],
            // },
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'resultsBlock',
              items: [
                {
                  component: 'SearchResultsMarkdown',
                  props: {
                    contentPrefix: 'results',
                  },
                },
                { component: 'Results' },
              ],
            },
            {
              type: 'statusBlock',
              items: [{ component: 'Status' }, { component: 'SiteSupport' }],
            },
          ],
        },
        favourites: {
          sections: [
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [{ component: 'FavouritesStatus' }, { component: 'SiteSupport' }],
            },
          ],
        },
      },

      resultItem: {
        service: {
          components: [
            {
              component: 'Header',
              options: {
                type: 'logo',
                showDistance: true,
                showSeparator: true,
              },
            },
            {
              component: 'Title',
            },
            {
              component: 'Details',
              fields: [
                { key: 'operatingDays', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
            },
          ],
        },
      },
    },
  },

  categories: [
    {
      id: 'default',
      title: 'Information and Services',
      search: true,
      show: 'never',
      categories: [],
    },
    {
      id: 'k-early',
      title: 'At Risk/Early Intervention',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [80, 83, 93, 120] }, { tags: [1305] }],
            not: [{ tags: [1306] }],
          },
        },
      },
    },
    {
      id: 'k-mild',
      title: 'Mild',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [80, 83, 93, 95] }, { tags: [1307] }],
            not: [{ tags: [1308] }],
          },
        },
      },
    },
    {
      id: 'k-moderate',
      title: 'Moderate',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [83, 93, 95] }, { tags: [1309] }],
            not: [{ tags: [1310] }],
          },
        },
      },
    },
    {
      id: 'k-severe',
      title: 'Severe',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [83, 93, 95] }, { tags: [1311] }],
            not: [{ tags: [1312] }],
          },
        },
      },
    },
    {
      id: 'emergency-services',
      title: 'Emergency Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [147, 140, 126] }],
          },
        },
      },
    },
    {
      id: 'general-practitioners',
      title: 'General Practitioners',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [141] }],
          },
        },
      },
    },
    {
      id: 'mental-health-practitioners',
      title: 'Mental Health Practitioners',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [83, 93, 95] }],
          },
        },
      },
    },
    {
      id: 'child-and-youth-mental-health',
      title: 'Child and Youth Mental Health',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [83, 120, 124] }],
          },
        },
      },
    },
    {
      id: 'inpatient-mental-health-services',
      title: 'Inpatient Mental Health Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [84, 140] }],
          },
        },
      },
    },
    {
      id: 'mental-health-peer-support',
      title: 'Mental Health Peer Support',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [83] }],
          },
        },
      },
    },
    {
      id: 'suicide-bereavement-support',
      title: 'Suicide Bereavement Support',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [95] }],
          },
        },
      },
    },
    {
      id: 'gambling',
      title: 'Financial & Gambling Support',
      search: true,
      categories: [92],
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [92] }],
          },
        },
      },
    },
    {
      id: 'alcohol-and-drug-support',
      title: 'Alcohol and Drug Support',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [79] }],
          },
        },
      },
    },
    {
      id: 'social-support-services',
      title: 'Social Support Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [131, 93] }],
          },
        },
      },
    },
    {
      id: 'atsi',
      title: 'Aboriginal & Torres Strait Is. Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [86, 87, 88] }],
          },
        },
      },
    },
    {
      id: 'lgbtqi',
      title: 'LGBTQIA+ Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [{ categories: [139] }],
          },
        },
      },
    },
  ],

  search: {
    location: {
      showLocationDialog: true,
      initLocation: 'dialog',
      disableStateLabel: true,
      filters: {
        states: ['WA'],
      },
      defaultLocation: {
        type: 'locality',
        id: 10687,
        default: true,
        name: 'Perth',
        state: 'WA',
        label: 'Perth 6000',
        councilId: 177,
        postcode: '6000',
        lat: -31.95053,
        lng: 115.86046,
        url: 'Perth',
        councilUrl: 'Perth',
        stateUrl: 'WesternAustralia',
      },
      // defaultLocationLabel: "Gladstone",
    },
    survey: {
      autoSelectSingleCategory: true,
    },
    queries: [
      {
        id: 'services',
        builder: 'directory',
        parameters: [
          { param: 'page' },
          {
            param: 'sort',
            sortField: 'TrustedRelevance',
            sortDirection: 'ASC',
          },
          { param: 'query' },
          // { param: 'categories' },
          { param: 'location' },
          { param: 'radius' },
          { param: 'age' },
          { param: 'gender' },
          { param: 'filters' },
        ],
      },
    ],

    searches: {
      services: {
        id: 'services',
        type: 'directory',
        pageSize: 100,
        showSiteSupport: true,
        resultsHeader: {
          type: 'markdown',
          prefix: 'results',
        },
      },
      favourites: {
        id: 'favourites',
        type: 'favourites',
      },
    },
  },

  assets,
  survey,
}

export default config
