import React from 'react'

import { observer } from 'mobx-react-lite'

import { withStyles } from '@mui/styles'
import { Box, Fade, Collapse, Typography, Link, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.covidAlert.main,
    color: theme.palette.covidAlert.contrastText,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    // margin: theme.spacing(0.75),
    // boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.2)',

    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(0),
    //   // boxShadow: '0 0px 4px 0px rgba(0, 0, 0, 0.4)',
    //   boxShadow: 'none',
    // },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 2, 2),
  },
  link: {
    // color: '#fff',
    color: theme.palette.covidAlert.contrastText,
    textDecoration: 'none',
  },
  closeButton: {
    // color: '#fff',
    color: theme.palette.covidAlert.contrastText,
    flexShrink: 1,
  },
})

const CovidAlert = observer(({ classes }) => {
  const store = useStore()
  const state = store.search.params.location?.state
  const showAlert = !store.search.ui.covidAlertShown

  const { tenantConfig: tc } = useConfig()
  const config = tc.covidAlert

  if (!config) return null

  const items = [state, 'AUS'].map(x => config?.[x]).filter(x => !!x)

  const handleClose = () => {
    store.search.ui.setCovidAlertShown(true)
  }

  return (
    <Collapse in={showAlert}>
      <Fade in={showAlert}>
        <Box className={classes.root}>
          <div className={classes.content}>
            <Typography
              variant="body1"
              sx={{ fontSize: '1rem', fontWeight: 'medium' }}
              gutterBottom
            >
              Coronavirus (COVID-19) Health Alerts
            </Typography>
            {items.map(({ label, href }, i) => (
              <Typography key={i} variant="body1" sx={{ fontSize: '0.86rem', pl: 0 }}>
                <Box component="i" sx={{ pr: 1 }} className="far fa-exclamation-triangle" />
                <Link className={classes.link} href={href} target="_blank">
                  {label}
                </Link>
              </Typography>
            ))}
          </div>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Fade>
    </Collapse>
  )
})

export default withStyles(styles, { name: 'AMSCovidAlert' })(CovidAlert)
