/* eslint-disable @typescript-eslint/no-explicit-any */
import { types, getRoot } from 'mobx-state-tree'

import { formatDistance } from '../utils/store-utils'

export const Event = types
  .model({
    type: types.literal('event'),
    id: '',
    search: '',
    name: '',
    day: '',
    startDay: '',
    startMonth: '',
    time: '',
    address: '',
    phone: types.maybeNull(types.string),
    description: '',
    url: '',
    lat: 0.0,
    lng: 0.0,
    distance: 0.0,
    serviceChanges: types.maybeNull(
      types.model({
        openingHours: '',
        serviceChangesOrClosures: '',
        extraServicesOrEvents: '',
        emergencyContact: '',
        eligibilityConditions: '',
      })
    ),
  })

  .views(self => ({
    get root(): any {
      return getRoot(self) as any
    },

    get geometry() {
      return {
        lat: self.lat,
        lng: self.lng,
      }
    },

    get addressWithheld() {
      return self.address === '--withheld--'
    },

    get distanceLabel() {
      return formatDistance(self.distance)
    },

    get dateString() {
      if (!(self.day && self.startDay && self.startMonth)) return null

      return `${self.day} ${self.startDay} ${self.startMonth}`
    },

    get isFavourite() {
      return this.root.search.isFavourite(self.id)
    },
  }))
