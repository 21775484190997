import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1, 0),

    // maxWidth: '100px',
    // '& > *': {
    //   border: '1px solid rgba(0, 0, 0, 0.4)',
    // },
  },
  icon: {
    marginRight: 8,
  },
}))

const IconText = ({
  iconClass = 'fas fa-alien-monster',
  text,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <i className={clsx(iconClass, 'fa-fw')} />
      </div>
      <div>{text}</div>
    </div>
  )
}

export default IconText
