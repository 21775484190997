import React from 'react'

import { Box } from '@mui/material'
import { useConfig } from 'config/config'

const NetworkMember = props => {
  const { tenantConfig: tc } = useConfig()

  const { config, data } = props || {}
  const { image, imageProps, networks = [] } = config.options || {}

  const imgSrc = tc.assets[image]
  const isMember = data?.networks?.some(n => networks.includes(n.networkId))

  if (!isMember || !imgSrc) return null

  return <Box as="img" src={imgSrc} {...imageProps} />
}

export default NetworkMember
