import React from 'react'
import { observer } from 'mobx-react-lite'

import { Redirect } from 'react-router-dom'
import { useStore } from 'data/store/store'

// This is a makeshift redirect to search that will set preset store values
// from props defined in route config

const SearchStoreRedirect = observer(({ to, tab, selectedCategories }) => {
  const store = useStore()

  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    store.search.params.setSelectedCategories(selectedCategories)
    store.search.ui.setSelectedTab(tab)
    setReady(true)
  }, [])

  if (!ready) return null

  return <Redirect to={to} />
})

export default SearchStoreRedirect
