import humps from 'lodash-humps'
import _ from 'lodash'

import { client } from './cie-api'

const toGeography = ({ member, ...gm }) => {
  return {
    type: 'geography',
    id: gm.geographyId,
    label: gm.title,
    dataset: gm.dataset,
    lat: gm.centralLatitude,
    lng: gm.centralLongitude,
    area: gm.area,
    kmlUrl: gm.kmlLocation,
    lgas: gm.lgas,

    // sponsors (.../geographies/members/...)
    ...(member && ({ member })),
  }
}

export const getGeographyById = async id => {
  const result = humps(await client.get(`/system/geographies/id/${id}`)).data

  if (!result.data || !result.success) return null

  return toGeography(result.data)
}

export const getGeographyByDataset = async dataset => {
  const geog = humps(await client.get(`/system/geographies/${dataset}`)).data

  if (!geog.data || !geog.success) return null

  const lgas = (await Promise.all(
    geog.data.map(g => client.get(`/system/geographies/intersections/LGA/id/${g.geographyId}`))
  ))
  .map(humps)
  .map(gis => gis.data?.data.map(gi => gi.otherGeographyId))

  const res = _.zip(geog.data, lgas)
    .map(g => ({ ...g[0], lgas: g[1] }))
    .map(toGeography)

  return res
}

export const getGeographyMembers = async ({ id, lat, lng }) => {
  if (!id) return []

  const result = await client.get(`/system/geographies/members/${lat}/${lng}`)

  if (!result.data?.data || !result.data?.success) return null

  return humps(result.data.data).map(toGeography)
}

// select

const selectFetches = {
  unmapped: async source => {
    console.error('unmaped selectFetch', source)
  },
  geography: async source => {
    const res = await getGeographyById(source.id)
    return [{
      ...res,
      label: source.label || res.label,
      default: source.default,
    }]
  },
  dataset: async source => {
    const res = await getGeographyByDataset(source.id)
    const ress = _.orderBy(res, ['label'], ['asc'])
    return ress
  },
}

export const getGeographySelectOptions = async paramConfig => {
  const sourceFetches = paramConfig.sources.map(s => {
    const fn = s.type in selectFetches ? selectFetches[s.type] : selectFetches.unmapped
    return fn(s)
  })

  const allResults = _.flatten((await Promise.all(sourceFetches)).filter(x => x))
  return allResults
}
