import React, { createContext, useContext } from 'react'

import _ from 'lodash'
import { toJS, makeAutoObservable, reaction, isObservableMap, entries } from 'mobx'

import { store as rootStore } from 'data/store/store'
import { routeStore } from 'data/store/route/RouteStore'
import { config } from 'config/config'
import { debugHelperConfig, buildConfigMap } from './config'

import { RouteMachineDebug } from './RouteMachine'

const storageKey = `ams-debugHelperStore-${config.appConfig.tenant || 'default'}`

export class DebugStore {
  config = null
  rootStore = null
  routeConfigStore = null
  routeMachine = null

  stores = null

  show = false
  saveHandler = null
  selectedHelperPath = null
  reactQueryDevtools = false
  environment = process.env.NODE_ENV

  _helper = null
  _helperMenuState = null

  constructor() {
    makeAutoObservable(this, {
      // config: false,
      rootStore: false,
      // routeConfigStore: false,
    })

    this.config = debugHelperConfig

    this.rootStore = rootStore
    this.routeConfigStore = routeStore
    this.routeMachine = new RouteMachineDebug(this)

    this.stores = {
      root: rootStore,
      route: routeStore,
    }

    this.show = false
    this.reactQueryDevtools = false

    this.saveHandler = reaction(
      () => this.asJson(),
      saveData => {
        const json = JSON.stringify(toJS(saveData), null, 2)
        window.localStorage.setItem(storageKey, json)
      },
      { delay: 1000 }
    )

    this._helperMenuState = _.mapValues(this.menu.pathMap, () => ({ expanded: true }))

    this.loadFromStorage()
  }

  get helperTest() {
    return { test: 'bang1!' }
  }

  get helpers() {
    return {
      // helperTest: { type: 'Json' },
      _searchRouteMachine: { type: 'RouteMachine' },
      root: { type: 'store', store: this.rootStore },
      route: { type: 'store', store: this.stores.route },
      // stores: this.stores,
    }
  }

  get route() {
    return this.stores.route
  }

  setRouteConfigStore(routeConfigStore) {
    this.routeConfigStore = routeConfigStore
  }

  loadFromStorage() {
    try {
      const json = window.localStorage.getItem(storageKey)
      if (!json) return

      const c = JSON.parse(json)
      if (!c) throw `Invalid json`

      this.show = c.show
      this.reactQueryDevtools = c.reactQueryDevtools
      this._helper = this.menu?.pathMap?.[c.selectedHelper]

      this.routeMachine.loadFromStorage(c.routeMachine || {})
    } catch (e) {
      console.error(e)
    }
  }

  asJson() {
    return {
      show: this.show,
      selectedHelper: this._helper?.pathStr,
      reactQueryDevtools: this.reactQueryDevtools,

      routeMachine: this.routeMachine.asJson(),
    }
  }

  get configMap() {
    return buildConfigMap(toJS(this.config))
  }

  toggleShow() {
    this.show = !this.show
  }

  toggleReactQuery() {
    this.reactQueryDevtools = !this.reactQueryDevtools
  }

  setHelper(path) {
    if (!(path in this.configMap)) return
    this.selectedHelperPath = path
  }

  setHelper2(helper) {
    // console.log({ helper })
    this._helper = helper
  }

  objectForHelper(helper = this._helper) {
    const obj = _.get(this.stores, helper.pathStr) || _.get(this.stores, helper.path)
    return obj
  }

  toggleMenuExpand(path) {
    const state = this._helperMenuState[path]
    if (!state) return
    state.expanded = !state.expanded
  }

  get selectedHelper() {
    return this.configMap[this.selectedHelperPath]
  }

  get menu() {
    let pathMap = {}

    const build = (node, { parentPath = [] } = {}) => {
      if (!node) return
      const { helpers: h } = node
      if (!h) return

      const { json, store, ...hr } = h

      const jh = json && json.reduce((prev, key) => ({ ...prev, [key]: { type: 'Json' } }), {})

      const allh = { ...hr, ...jh }

      const buildMapItems = (map, path) => {
        const mo = _.fromPairs(entries(map))
        const mh = _.mapValues(mo, s => ({ store: s }))
        console.log({ mo, helpers: mh })
        return build({ mo, helpers: mh }, { parentPath: path })
      }

      const items = _.sortBy(
        _.map(
          allh,
          (value, key) => {
            const { type, store, map } = value
            const path = [...parentPath, key]
            const pathStr = path.join('.')

            // if (key === '_searches') {
            //   console.log('_searches', value)
            // }

            return {
              _this: this,
              key,
              path,
              pathStr,
              ...value,
              ...(store && { type: 'store' }),
              ...(store && { items: build(store, { parentPath: path }) }),
              // ...(type === 'Json' && { json: node[key] }),
              // ...(type === 'Json' && { json: { key } }),

              ...(type === 'TestLinks' && { routes: value?.routes }),
            }
          },
          ['key']
        )
      )

      _.assign(pathMap, _.keyBy(items, 'pathStr'))

      return items
    }

    const h = build(this, { parentPath: [] })

    return {
      key: 'root',
      items: h,
      pathMap,
    }
  }
}

export const debugStore = new DebugStore()
window.debugStore = debugStore

const debugStoreContext = createContext(debugStore)

export const useDebugStore = () => {
  const store = useContext(debugStoreContext)
  return store
}
