import React from 'react'
import aboriginalflag from './aboriginalflag.png'
import torresflag from './torresflag.png'
import { makeStyles } from '@mui/styles'
import { useMediaQuery } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    background: '#f2f2f2',
  },
  country: {
    display: 'flex',
  },
  flagsGroup: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '100px',
  },
  flag: { maxHeight: '70px', padding: '10px 15px' },
  text: {
    width: '70%',
    fontSize: '15px',
    padding: '10px',
    margin: '10px 200px 10px 0px',
  },
  livedExperienceText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    padding: '8px 220px',
  },
  root_mobile: {
    // display: 'flex',
    background: '#f2f2f2',
  },
  country_mobile: {
    display: 'flex',
  },
  flagsGroup_mobile: {
    // width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: '100px',
  },
  flag_mobile: { maxHeight: '60px', padding: '10px 15px' },
  text_mobile: {
    width: '70%',
    fontSize: '15px',
    padding: '10px',
    // margin: '10px 200px 10px 0px',
  },
  livedExperienceText_mobile: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    padding: '12px',
  },
}))

function Acknowledgement() {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return !isMobile ? (
    <div className={classes.root}>
      <div className={classes.country}>
        <div className={classes.flagsGroup}>
          <img className={classes.flag} src={aboriginalflag}></img>
          <img className={classes.flag} src={torresflag}></img>
        </div>
        <div className={classes.text}>
          The Mental Health Commission acknowledges Aboriginal and Torres Strait Islander people as
          the Traditional Custodians of this country and its waters. The Commission wishes to pay
          its respects to Elders past and present and extend this to all Aboriginal people seeing
          this message.
        </div>
      </div>
      <div className={classes.livedExperienceText}>
        The Mental Health Commission would also like to acknowledge the individual and collective
        expertise of those with living or lived experience of mental health, alcohol and other drug
        issues. We recognise the vital contribution you make by sharing your unique experience to
        achieve better outcomes for all.
      </div>
    </div>
  ) : (
    <div className={classes.root_mobile}>
      <div className={classes.country_mobile}>
        <div className={classes.flagsGroup_mobile}>
          <img className={classes.flag_mobile} src={aboriginalflag}></img>
          <img className={classes.flag_mobile} src={torresflag}></img>
        </div>
        <div className={classes.text_mobile}>
          The Mental Health Commission acknowledges Aboriginal and Torres Strait Islander people as
          the Traditional Custodians of this country and its waters. The Commission wishes to pay
          its respects to Elders past and present and extend this to all Aboriginal people seeing
          this message.
        </div>
      </div>
      <div className={classes.livedExperienceText_mobile}>
        The Mental Health Commission would also like to acknowledge the individual and collective
        expertise of those with living or lived experience of mental health, alcohol and other drug
        issues. We recognise the vital contribution you make by sharing your unique experience to
        achieve better outcomes for all.
      </div>
    </div>
  )
}

export default Acknowledgement
