import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@mui/styles'
import { Container, Box, OutlinedInput } from '@mui/material'

import LocationInput from '../location/LocationInput'

import { useConfig } from 'config/config'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  testLink: {
    fontSize: 24,
    fontWeight: 600,
  },
  logo: {
    maxHeight: 40,
  },
}))

const Scratch = observer(() => {
  // const store = useStore()
  const classes = useStyles()

  const { tenantConfig: tc } = useConfig()

  const srcStatic = '/tmp/vct-south-west.svg'
  const srcWebpack = tc?.assets?.sponsors?.vctSouthWest

  return (
    <Container className={classes.root}>
      {/* <Box sx={{ my: 2, width: 400 }}>
        <OutlinedInput value="Bang!" />
      </Box> */}
      <Box sx={{ my: 2, width: 400 }}>
        <LocationInput />
      </Box>

      {/* <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <img className={classes.logo} src={srcStatic} alt="" />
        <img className={classes.logo} src={srcWebpack} alt="" />
      </Box> */}
    </Container>
  )
})

export default Scratch
