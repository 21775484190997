import React from 'react'

import { makeStyles } from '@mui/styles'
import {
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0),
  },
}))

const LabelSwitch = ({
  checked = false,
  label = '',
  onChange = () => undefined,
}) => {
  const classes = useStyles()

  const handleChange = event => {
    onChange(event.target.checked)
  }

  return (
    <FormGroup row className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            name="check"
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default LabelSwitch
