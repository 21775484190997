import React, { useState } from 'react'

// import clsx from 'clsx'

import { observer } from 'mobx-react-lite'

import { BrowserRouter as Router } from 'react-router-dom'

import { useAnalytics } from 'use-analytics'

import { styled } from '@mui/material/styles'
import { withStyles } from '@mui/styles'

import { SwipeableDrawer } from '@mui/material'

import AppBar from 'ui/nav/app-bar/AppBar'
import MainMenu from 'ui/nav/menus/MainMenu'
import TenantRoutes from 'ui/router/TenantRoutes'

import { useConfig } from 'config/config'
import { useResizeDetect } from 'ui/hooks/useResizeDetect'
import { useAppAnalytics } from 'ui/hooks/useAppAnalytics'
import { usePlatform } from 'ui/hooks/usePlatform'

import { useDebugStore } from 'data/store/debug/DebugStore'
const { DebugHelper } = require('ui/debug/DebugHelper')
import { ReactQueryDevtools } from 'ui/debug/ReactQuery'
import useGoogleAnalyticsTagManager, {
  removeDuplicateScript,
} from '../hooks/useGoogleAnalyticsTagManager'
import _ from 'lodash'
// import { MstReduxDevToolsProvider } from 'data/store/debug/mstReduxDevTools'

// const bottomOffset = 0

// prevent ios full window scroll from touch
const preventDefault = e => e.preventDefault()

window.addEventListener('touchmove', preventDefault, {
  passive: false,
})

// window.removeEventListener('touchmove', preventDefault)

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: theme.palette.background.image,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '110%',
    // backgroundColor: '#f00',
    maxHeight: props => props.height,
    height: props => props.height,
    // maxHeight: '100vh',
    // height: '100vh',
    maxWidth: '100vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    flex: '0 1 0',
  },
  content: {
    flex: `1 0 0`,
    overflow: 'hidden',
  },
})

const DebugHelper_ = () => {
  const { appConfig } = useConfig()
  if (!appConfig.debugHelpers) return null
  return <DebugHelper />
}

const ReactQueryDevtools_ = observer(() => {
  const { appConfig } = useConfig()
  const debug = useDebugStore()

  if (!appConfig.debugHelpers || !debug.reactQueryDevtools) return null

  return (
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{ className: 'ReactQueryDevtoolsToggleButton' }}
    />
  )
})

const App = withStyles(styles, { name: 'AMSApp' })(props => {
  const { classes } = props
  const { tenantConfig: tc } = useConfig()
  const { track } = useAnalytics()

  const [mainMenu, setMainMenu] = useState(false)

  const handleClickMenu = () => {
    track('mainMenuToggle', {
      category: 'Navigation',
      label: 'Main Menu',
    })
    toggleMenu(!mainMenu)()
  }

  const toggleMenu = open => () => {
    setMainMenu(open)
  }

  // hooks have to go under router

  const AppAnalytics = () => {
    useAppAnalytics()
    if (!_.isUndefined(tc.analytics?.googleAnalytics?.containerId)) {
      useGoogleAnalyticsTagManager(
        tc.analytics?.googleAnalytics?.containerId,
        tc.analytics?.googleAnalytics?.isDirectInstall
      )
      removeDuplicateScript()
    }
    return null
  }

  return (
    // <MstReduxDevToolsProvider>
    <Router>
      {}
      {/* <Headers /> */}
      <DebugHelper_ />
      <AppAnalytics />
      <div className={classes.root}>
        <AppBar onMenuClick={handleClickMenu} {...tc.ui?.appBar} />
        <div className={classes.content}>
          <TenantRoutes />
          <ReactQueryDevtools_ />
        </div>
      </div>
      <SwipeableDrawer
        anchor="left"
        open={mainMenu}
        onOpen={toggleMenu(true)}
        onClose={toggleMenu(false)}
      >
        <MainMenu menu="navigation" onToggleMenu={toggleMenu} />
      </SwipeableDrawer>
    </Router>
    // </MstReduxDevToolsProvider>
  )
})

const AppContainer = () => {
  const { height } = useResizeDetect()
  const platform = usePlatform()

  const appHeight = platform.ieVersion ? height - 1 : height

  return <App height={appHeight} />
}

export default AppContainer
