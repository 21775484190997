import React from 'react'

import _ from 'lodash'
import { makeStyles } from '@mui/styles'
import { Box, FormLabel, FormControl, FormControlLabel, Button, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { resolveVariantText, resolveVariantStrings } from 'data/store/utils/survey-utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelRoot: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(1.1),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}))

const SurveyButtons = ({ question, pathway }) => {
  const classes = useStyles()

  const strings =
    resolveVariantStrings(_.pick(question, ['question', 'description', 'suffix']), pathway) || {}

  const { buttons } = question
  if (!_.isArray(buttons)) return null

  return (
    <FormControl className={classes.root}>
      <FormLabel sx={{ mb: 2 }}>{strings.question}</FormLabel>

      {strings.description && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          {strings.description}
        </Typography>
      )}

      <Box sx={{ display: 'flex', mb: 2, gap: 1 }}>
        {buttons.map(({ label, icon, ...buttonProps }, i) => {
          return (
            <Button
              key={i}
              component={buttonProps?.to && RouterLink}
              startIcon={icon && <i className={icon} />}
              {...buttonProps}
            >
              {label}
            </Button>
          )
        })}
      </Box>

      {strings.suffix && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          {strings.suffix}
        </Typography>
      )}
    </FormControl>
  )
}

export default SurveyButtons
