import { toJS } from 'mobx'
import { types, getRoot } from 'mobx-state-tree'

import _ from 'lodash'
import { Locality } from './Locality'
import { Geography } from './Geography'

import { useConfig } from 'config/config'
import { analytics } from 'data/analytics/analytics'
import {
  evaluateStepRules,
  mapInterestCategories,
  mapScaleEvalCategories,
  mapPropertyCategories,
} from '../utils/survey-utils'
import { cacheScores, evalKessler } from '../utils/scale-eval'

const { tenantConfig } = useConfig()

const Location = types.union(Locality, Geography)

const pathwaysMap = (tenantConfig.survey.pathways || []).reduce((acc, cur) => {
  cur.pathways.forEach(p => {
    acc[p] = cur
  })
  return acc
}, {})

const scaleMap = _.keyBy(
  tenantConfig.survey.questions
    .filter(q => q.type === 'scaleeval')
    .map(q => ({
      id: q.id,
      question: q,
      scoreCache: cacheScores(q.scoring),
    })),
  'id'
)

export const ScaleEval = types
  .model({
    id: '',
    values: types.array(types.maybeNull(types.number, null)),
  })

  .views(self => ({
    get question() {
      return scaleMap[self.id].question
    },
    get scoreCache() {
      return scaleMap[self.id].scoreCache
    },
    get complete() {
      return !self.values.some(v => v === null)
    },

    get evalScore() {
      if (!self.question || !self.scoreCache) return { total: 0, score: 'none' }

      return evalKessler(self.question, self.values, self.scoreCache)
    },

    get payload() {
      return {
        ...self,
        complete: self.complete,
        score: self.evalScore,
      }
    },
  }))

  .actions(self => ({
    init(question, scoreCache) {
      self.question = question
      self.scoreCache = scoreCache
    },

    setValue(index, value) {
      self.values[index] = value
    },
  }))

export const Survey = types
  .model({
    pathway: '',
    finished: false,
    step: 0,
    radius: 75,
    location: types.maybeNull(Location),
    categories: types.array(types.string),
    interests: types.array(types.string),
    age: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    sexuality: types.maybeNull(types.string),
    atsi: types.maybeNull(types.string),
    scaleEval: types.map(ScaleEval),
    categoriesModified: false,
  })

  .views(self => ({
    get pathwayConfig() {
      return pathwaysMap[self.pathway]
    },

    get steps() {
      return self.pathwayConfig?.steps
    },

    get currentStep() {
      return self.step < self.steps?.length ? self.steps[self.step] : null
    },

    get currentStepValid() {
      return evaluateStepRules(self, self.currentStep, 'validation')
    },

    get prevStep() {
      let psi = self.step - 1

      while (psi > -1) {
        if (evaluateStepRules(self, self.steps[psi], 'when')) return psi
        --psi
      }

      return null
    },

    get nextStep() {
      let nsi = self.step + 1

      while (nsi < self.steps?.length) {
        if (evaluateStepRules(self, self.steps[nsi], 'when')) return nsi
        ++nsi
      }

      return null
    },

    mappedCategoriesForStep(s) {
      // console.log('mappedCategoriesForStep', s)
      return s.mapCategories.map(m => {
        const q = tenantConfig.survey.questionsMap[m.question]

        switch (q.type) {
          case 'interests':
            return mapInterestCategories(q, self.interests.slice())

          case 'scaleeval': {
            const se = self.scaleEval.get(q.id)
            if (!se || !se.complete) return {}

            return mapScaleEvalCategories(se.evalScore.score, m.categories)
          }

          // property
          default: {
            if (!(m.question in self)) {
              console.error(`Invalid survey mapCategories key ${m.question}`)
              return {}
            }
            return mapPropertyCategories(self[m.question], m.categories)
          }
        }
      })
    },

    // resolves combined category mapping from active steps
    // flattens nested arrays of { categories, selected }

    get mappedCategories() {
      const mapped = _.flatten(
        self.steps
          .filter(s => 'mapCategories' in s)
          .filter(s => evaluateStepRules(self, s, 'when'))
          .map(s => self.mappedCategoriesForStep(s))
      )

      const selected = mapped
        .filter(m => m.selected)
        .map(m => m.selected)
        .reverse()

      const defaults = tenantConfig.survey.defaults?.categories || []

      const res = {
        categories: _.uniq(
          _.flatten([
            ...defaults,
            self.categories.slice(),
            _.flatten(mapped.map(m => m.categories)),
          ])
        ).filter(c => !!c),
        selectedCategories: selected.length > 0 ? selected[0] : null,
      }

      return res
    },

    get scaleEvalPayload() {
      // get scaleEval questions

      const seids = tenantConfig.survey.questions.filter(q => q.type === 'scaleeval').map(q => q.id)

      // filter out steps

      const vs = _.uniq(
        _.flatten(
          self.steps
            .filter(
              s =>
                _.intersection(
                  seids,
                  s.questions.map(q => q.id)
                ).length > 0
            )
            .filter(s => evaluateStepRules(self, s, 'when'))
            .map(s => s.questions.map(q => q.id))
        )
      ).map(id => self.scaleEval.get(id).payload)

      return vs
    },

    get result() {
      return {
        ...self,
        ...(self.pathwayConfig?.result?.mappedCategories && self.mappedCategories),
      }
    },
  }))

  .actions(self => ({
    setSteps(steps) {
      self.steps = steps
    },

    set(key, value) {
      if (key in self) {
        self[key] = value
      }
    },

    setLocation(location) {
      self.location = location
    },

    init(pathway = 'self', location = 'null') {
      self.clear()
      self.pathway = pathway

      // populate all possible evals

      self.scaleEval = _.keyBy(
        tenantConfig.survey.questions
          .filter(q => q.type === 'scaleeval')
          .map(q => ({
            id: q.id,
            values: new Array(q.questions.length).fill(null),
          })),
        'id'
      )

      // location

      self.location = toJS(getRoot(self).search.params.location)
    },

    clear() {
      getRoot(self).resetSurvey()
      // self.clear()
    },

    dump() {
      console.log('survey', self.result)
    },

    applyMappedCategories() {
      if (self.categoriesModified) return

      self.categories = _.uniq([...self.categories, ...self.mappedCategories?.categories])
      self.categoriesModified = true
    },

    finish() {
      const search = getRoot(self).search

      analytics.track('surveyImplComplete', {
        category: 'SurveyImpl',
        label: 'Complete',
        survey: {
          ...self.result,
          scaleEval: self.scaleEvalPayload,
        },
        // results
      })

      search.params.updateFromSurvey(self.result)

      search.ui.clear()
      search.ui.updateFromSurvey(self)

      self.finished = true
    },
  }))
