import { surveyShared } from '../shared/survey'

import keyBy from 'lodash/keyBy'

const kprereq = [
  'interests',
  // 'alcoholdrug',
  'contains',
  ['isolation', 'event', 'stress', 'feelings'],
]

const kcatmap = {
  early: {
    categories: ['k-early'],
  },
  mild: {
    categories: ['k-mild'],
  },
  moderate: {
    categories: ['k-moderate', 'mental-health-practitioners', 'inpatient-mental-health-services'],
  },
  severe: {
    categories: [
      'k-severe',
      'emergency-services',
      'mental-health-practitioners',
      'inpatient-mental-health-services',
    ],
  },
}

const questions = [
  ...surveyShared.questions,
  {
    id: 'categories',
    type: 'categories',
    label: 'Categories',
    icon: 'fa-solid fa-bars-staggered',
    question: {
      self: 'What are you interested in?',
      other: 'What are they interested in?',
      alcoholdrug: 'What are they interested in?',
    },
    // categoryGroups: [
    //   {
    //     title: 'Services',
    //     categories: [
    //       'emergency-services',
    //       'general-practitioners',
    //       'mental-health-practitioners',
    //       'social-support-services',
    //     ],
    //   },
    //   {
    //     title: 'Information',
    //     categories: [
    //       'online-resources-and-information',
    //     ],
    //   },
    // ],
  },
  // {
  //   id: 'interests',
  //   type: 'interests',
  //   label: 'Interests',
  //   question: null,
  //   interests: [
  //     {
  //       id: 'substance',
  //       question: {
  //         self: "I'm concerned about my alcohol and/or other drugs usage",
  //         other: "They're concerned about their alcohol and/or other drugs usage",
  //         alcoholdrug: "I'm concerned about my alcohol and/or other drugs usage",
  //       },
  //       categories: ['alcohol-and-drug-support'],
  //     },
  //   ],
  // },
  {
    id: 'interests',
    type: 'interests',
    label: 'Interests',
    question: null,
    interests: [
      {
        id: 'isolation',
        question: {
          alcoholdrug: "I'm feeling lonely and/or isolated",
          mental: "I'm feeling lonely and/or isolated",
        },
        categories: [
          'mental-health-practitioners',
          // 'child-and-youth-mental-health',
          'mental-health-peer-support',
          'mental-health-support-lines',
          'social-support-services',
        ],
      },
      {
        id: 'substance',
        question: {
          alcoholdrug: "I'm concerned about my alcohol and/or other drugs usage",
          mental: "I'm concerned about my alcohol and/or other drugs usage",
        },
        categories: ['alcohol-and-drug-support'],
      },
      {
        id: 'gambling',
        question: {
          alcoholdrug: "I'm concerned about my gambling",
          mental: "I'm concerned about my gambling",
        },
        categories: ['gambling'],
      },
      {
        id: 'event',
        question: {
          alcoholdrug: 'I have gone through an upsetting event which I would like help with',
          mental: 'I have gone through an upsetting event which I would like help with',
        },
        categories: [
          'mental-health-practitioners',
          // 'child-and-youth-mental-health',
          'mental-health-peer-support',
          'suicide-bereavement-support',
        ],
      },
      {
        id: 'stress',
        question: {
          alcoholdrug: "I'm feeling down, stressed and/or can't stop worrying",
          mental: "I'm feeling down, stressed and/or can't stop worrying",
        },
        categories: [
          'mental-health-practitioners',
          // 'child-and-youth-mental-health',
          'mental-health-peer-support',
          'social-support-services',
        ],
      },
      {
        id: 'feelings',
        question: {
          alcoholdrug: "I'd like to talk to someone about my emotions/feelings",
          mental: "I'd like to talk to someone about my emotions/feelings",
        },
        categories: [
          'mental-health-practitioners',
          'mental-health-peer-support',
          // 'child-and-youth-mental-health',
        ],
      },
    ],
  },
]
export const survey = {
  questions,

  // TODO: move this and the merge to the config loader
  questionsMap: keyBy(questions, 'id'),
  defaults: {
    categories: [
      // 'child-and-youth-mental-health',
      // 'emergency-services',
      // 'lgbtqi',
    ],
  },

  pathways: [
    {
      pathways: ['alcoholdrug', 'mental'],
      result: {
        mappedCategories: true,
        finishRedirect: '/search',
      },
      steps: [
        {
          title: 'Location',
          questions: [{ id: 'location' }, { id: 'radius' }],
          validation: [['location', 'neq', null]],
        },
        // {
        //   title: {
        //     self: 'About You',
        //     other: 'About Them',
        //   },
        //   questions: [{ id: 'age' }, { id: 'gender' }, { id: 'atsi' }, { id: 'sexuality' }],
        //   mapCategories: [
        //     {
        //       question: 'age',
        //       categories: {
        //         '0 - 15': { categories: ['child-and-youth-mental-health'] },
        //       },
        //     },
        //     {
        //       question: 'atsi',
        //       categories: {
        //         Yes: { categories: ['atsi'] },
        //       },
        //     },
        //     {
        //       question: 'sexuality',
        //       categories: {
        //         Yes: { categories: ['lgbtqi'] },
        //       },
        //     },
        //   ],
        // },
        {
          title: {
            alcoholdrug: 'What would you like help with?',
            mental: 'What would you like help with?',
          },
          questions: [{ id: 'interests' }],
          mapCategories: [{ question: 'interests' }],
        },
        {
          title: 'How are you feeling?',
          questions: [{ id: 'k5' }],
          when: [['pathway', 'eq', 'self'], ['atsi', 'eq', 'Yes'], kprereq],
          mapCategories: [
            {
              question: 'k5',
              categories: kcatmap,
            },
          ],
        },
        {
          title: 'How are you feeling?',
          questions: [{ id: 'k10' }],
          when: [['pathway', 'eq', 'self'], ['atsi', 'neq', 'Yes'], kprereq],
          mapCategories: [
            {
              question: 'k10',
              categories: kcatmap,
            },
          ],
        },
      ],
    },
    // {
    //   pathways: ['alcoholdrug'],
    //   result: {
    //     mappedCategories: true,
    //     finishRedirect: '/search',
    //   },
    //   steps: [
    //     {
    //       title: 'Location',
    //       questions: [{ id: 'location' }, { id: 'radius' }],
    //       validation: [['location', 'neq', null]],
    //     },
    //     {
    //       title: {
    //         self: 'What would like help with?',
    //         other: 'What would they like help with?',
    //         alcoholdrug: 'What would you like help with??',
    //       },
    //       questions: [{ id: 'alcoholdrug' }],
    //       mapCategories: [{ question: 'interests' }],
    //     },
    //     {
    //       title: 'How are you feeling?',
    //       questions: [{ id: 'k5' }],
    //       when: [['pathway', 'eq', 'self'], ['atsi', 'eq', 'Yes'], kprereq],
    //       mapCategories: [
    //         {
    //           question: 'k5',
    //           categories: kcatmap,
    //         },
    //       ],
    //     },
    //     {
    //       title: 'How are you feeling?',
    //       questions: [{ id: 'k10' }],
    //       when: [['pathway', 'eq', 'self'], ['atsi', 'neq', 'Yes'], kprereq],
    //       mapCategories: [
    //         {
    //           question: 'k10',
    //           categories: kcatmap,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
  categoryMapping: [
    {
      type: 'k5',
      question: 'k5',
    },
  ],
}
