import React, { useState, useEffect, useRef } from 'react'

import clsx from 'clsx'
import scrollIntoView from 'scroll-into-view-if-needed'

import { makeStyles } from '@mui/styles'
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Link,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  intro: {
    fontSize: 18,
  },
  emergencyButton: {
    fontSize: 20,
    fontWeight: 600,
  },
  grid: {
    marginTop: theme.spacing(4),
  },
  accordion: {
    backgroundColor: '#fafafa',
    margin: theme.spacing(2, 0),
    // boxShadow: 'none',
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.12)',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  groupTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  groupIcon: {
    marginRight: theme.spacing(2),
  },
  serviceLink: {
    // backgroundColor: '#f0f',
    ...theme.typography.body1,
    // marginTop: theme.spacing(2),
    // marginLeft: 'auto',
  },
  serviceLinkIcon: {
    marginRight: theme.spacing(1),
  },
}))

const ServiceLink = ({ icon, label, href }) => {
  const classes = useStyles()

  return (
    <div className={classes.serviceLink}>
      <Link color="primary" href={href} underline="none" target="_blank">
        <i className={clsx(icon, classes.serviceLinkIcon)} />
        {label}
      </Link>
      {/* {href} */}
    </div>
  )
}

const EmergencyItem = ({ title, description, phone, chatUrl, phones }) => {
  const classes = useStyles()

  if (phones) {
    const serviceLinkProps = []
    phones.map(phone =>
      serviceLinkProps.push({
        icon: 'fas fa-phone',
        label: `${phone.title}: ${phone.number}`,
        href: `tel:${phone.number.replace(/[^\d]/g, '')}`,
      })
    )
    return (
      <Box my={3} mx={2}>
        <Typography variant="h6" className={classes.groupTitle} gutterBottom>
          {title}
        </Typography>
        {serviceLinkProps && serviceLinkProps.map((i, key) => <ServiceLink key={key} {...i} />)}
        <Box mt={2}>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Box>
    )
  } else {
    const serviceLinkProps = phone
      ? { icon: 'fas fa-phone', label: phone, href: `tel:${phone.replace(/[^\d]/g, '')}` }
      : chatUrl
      ? { icon: 'fas fa-comments', label: 'Chat', href: chatUrl }
      : null

    return (
      <Box my={3} mx={2}>
        <Typography variant="h6" className={classes.groupTitle} gutterBottom>
          {title}
        </Typography>
        {serviceLinkProps && <ServiceLink {...serviceLinkProps} />}

        <Box mt={2}>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Box>
    )
  }
}

const EmergencyGroup = ({ group, expanded, expandId, onAccordionClick = () => undefined }) => {
  const classes = useStyles()

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      expanded={expanded === expandId}
      onChange={() => onAccordionClick(expandId)}
      TransitionProps={{
        timeout: 200,
        addEndListener: (node, done) => {
          if (expanded === expandId) {
            const el = document.getElementById(expandId)
            if (!el) return

            setTimeout(() => {
              scrollIntoView(el, {
                scollMode: 'always',
                behavior: 'smooth',
                block: 'start',
              })
            }, 200)
          }
        },
      }}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // aria-controls={id}
        id={expandId}
      >
        <Typography variant="h6" color="textSecondary">
          <i className={clsx(group.icon, classes.serviceLinkIcon)} />
          {group.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {group.items.map((item, i) => (
          <EmergencyItem key={i} {...item} />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

const EmergencyUsc = ({ data }) => {
  const classes = useStyles()
  const rootRef = useRef(null)
  const [expanded, setExpanded] = useState('')
  const groups = data.groups

  const handleAccordionClick = panel => {
    setExpanded(expanded === panel ? undefined : panel)
  }

  const scrollTo = ({ expanded }) => {
    if (!expanded) {
      const el = document.getElementById('scroll-content')
      if (!el) return

      el.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } else {
      const el = document.getElementById(expanded)
      el.scrollIntoView('start')
    }
  }

  useEffect(() => {
    scrollTo({ expanded })
    if (expanded) {
      document.getElementById('scroll-content').scrollTo({ top: 0 })
    }
  }, [expanded])

  return (
    <div ref={rootRef} className={classes.root}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        Emergency Help
      </Typography>
      <Divider />
      <br />

      <br />

      <Box textAlign="center">
        <Typography
          variant="body1"
          className={classes.intro}

          // textAlign="center"
        >
          If you or someone you know needs immediate assistance, please call
        </Typography>
        <br />

        <Button
          href="tel:000"
          variant="contained"
          color="primary"
          disableElevation
          className={classes.emergencyButton}
          // fontSize={18}
        >
          Emergency Services 000
        </Button>
      </Box>

      <br />
      <br />

      <div>
        {groups.map((group, i) => (
          <EmergencyGroup
            key={i}
            id={`${group.id}-${i}`}
            group={group}
            expanded={expanded}
            expandId={`${group.id}-${i}`}
            onAccordionClick={handleAccordionClick}
          />
        ))}
      </div>
    </div>
  )
}

export default EmergencyUsc
