import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import { useConfig } from 'config/config'
import { useRouteStore } from 'data/store/route/RouteStore'

import { RouteStoreUpdater } from 'data/store/route/RouteStore'

import Headers from 'ui/_main/Headers'
import * as amsComponents from 'ui/components'

const routeComponents = {
  ...amsComponents,
  Redirect,
}

const resolveComponent = name => {
  return name in routeComponents ? routeComponents[name] : undefined
}

const TenantRoutes = () => {
  const { tenantConfig } = useConfig()

  const routes = tenantConfig.routes.map((r, i) => {
    const RouteComponent = resolveComponent(r.component)
    const ContainerComponent = resolveComponent(r.container)

    // if (!RouteComponent) return <div>Invalid component: {r.component}</div>

    return (
      <Route key={i} exact={!!r.exact} path={r.path}>
        <RouteStoreUpdater />
        {!RouteComponent ? (
          <div>Invalid component: {r.component}</div>
        ) : ContainerComponent ? (
          <ContainerComponent {...r.containerProps}>
            <RouteComponent {...{ ...r.props }} />
          </ContainerComponent>
        ) : (
          <RouteComponent {...{ ...r.props }} />
        )}
      </Route>
    )
  })

  return (
    <>
      <Headers />
      <Switch>{routes}</Switch>
    </>
  )
}

export default TenantRoutes
