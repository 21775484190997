import React from 'react'

import { withStyles } from '@mui/styles'
import { Typography } from '@mui/material'

import Markdown from 'ui/markdown/Markdown'

import { withVariantStyles } from 'ui/theme/TenantTheme'
import { useConfig } from 'config/config'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  flags: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1),
    },
  },
  flag: {
    maxHeight: 30,
    margin: theme.spacing(0, 0.5),
  },
  text: {
    // fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0.5),
    },
  },
})

const copy = `
We acknowledge the traditional owners of the land and pay respects to the elders past, present and future.
`

const Acknowledgement = ({ classes }) => {
  const { tenantConfig } = useConfig()
  const flags = tenantConfig.assets.flags
  console.log(flags)

  const Flag = ({ src, alt }) => <img className={classes.flag} src={src} alt={alt} />

  return (
    <div className={classes.root}>
      <div className={classes.flags}>
        <Flag alt="Aboriginal Flag" src={flags.aboriginal} />
        <Flag alt="Torres Strait Flag" src={flags.torresStrait} />
      </div>
      <Typography className={classes.text} variant="body1" component="span">
        {copy}
      </Typography>
    </div>
  )
}

export default withStyles(styles, { name: 'AMSAcknowledgement' })(Acknowledgement)
