import React, { useEffect, useCallback } from 'react'

import _ from 'lodash'
import clsx from 'clsx'
import deepEqual from 'fast-deep-equal'
import { observer } from 'mobx-react-lite'
import { Link as ReactRouterLink } from 'react-router-dom'
// import { toJS } from 'mobx'
import { useAnalytics } from 'use-analytics'

import { useTheme, withStyles } from '@mui/styles'
import { Box, CircularProgress, Button, Typography, Divider, useMediaQuery } from '@mui/material'

import scrollIntoView from 'scroll-into-view-if-needed'

import { TenantThemeProvider } from 'ui/theme/TenantTheme'

import SearchResultItem from 'ui/search/results/Result'
import SearchResultsMarkdown from 'ui/search/results/SearchResultsMarkdown'

import Sponsors from 'ui/content/Sponsors'
import Acknowledgement from 'ui/content/Acknowledgement'
import CovidAlert from 'ui/search/results/CovidAlert'
import Promotions from 'ui/search/results/Promotions'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
import { toEnglishList } from 'data/store/utils/misc-helpers'
import { useCountRenders } from 'ui/hooks/useCountRenders'

import { useResultsControl } from './ResultsControl'
import { useParentMutationObservers } from 'ui/helpers/useParentMutationObservers'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingBottom: theme.spacing(2),
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    // justifyContent: 'center',
    padding: theme.spacing(2),
  },
  navLink: {
    textDecoration: 'none',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  alertBlock: {},
  initBlock: {},
  statusSponsorBlock: {},
  statusHeaderBlock: {},
  resultsBlock: {},
  statusBlock: {
    padding: theme.spacing(4, 0, 4),
  },
  footerBlock: {},
})

const ActionContainer = ({ sx, children, ...props }) => {
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
      data-nosnippet
    >
      {children}
    </Box>
  )
}

const Welcome = observer(props => {
  const { store } = props
  const w = store.search.welcome

  const { title = w.title, body = w.body, componentProps = {} } = props

  const {
    container: containerProps,
    root: rootProps,
    title: titleProps,
    body: bodyProps,
  } = componentProps

  if (store.search.params.location) return null

  return (
    <ActionContainer sx={{ mt: 4, alignItems: 'stretch' }} {...containerProps}>
      <Box sx={{ textAlign: 'center' }} {...rootProps}>
        <Typography variant="h6" sx={{ mb: 1, fontSize: '1rem' }} {...titleProps}>
          {title}
        </Typography>

        <Typography variant="body1" {...bodyProps}>
          {body}
        </Typography>
      </Box>
    </ActionContainer>
  )
})

const Results = observer(({ classes, search, store }) => {
  const { track } = useAnalytics()

  // useCountRenders(`${search.id}-Results`)
  const { selectedResult, setSelectedResult } = useResultsControl({
    subscriber: { searchId: search.id, control: 'results' },
  })

  const handleClickResult = React.useCallback(
    result => {
      track('searchResultsResultClick', {
        category: 'SearchResults',
        label: result.name || 'unknown',
      })

      setSelectedResult(result)
    },
    [store.search, track]
  )

  return (
    <Box sx={{ py: 0.5 }}>
      {search.results.map((data, i) => (
        <SearchResultItem
          key={i}
          search={search}
          data={data}
          selected={data.id === selectedResult?.result?.id}
          onClickResult={handleClickResult}
          variant="list"
        />
      ))}
    </Box>
  )
})

const StatusHeader = observer(({ classes, search, store, params }) => {
  if (!params.location) return null

  const prefix = search.loading ? 'Searching for' : `Top ${search.results.length} results for`

  const formatCategories = () => {
    const to = store.tenant.options
    const selected = params.selectedCategoriesMeta

    if (selected.length === 0) return to.defaultCategoryTitle

    const used = _.take(selected, to.maxVerboseCategories).map(c => c.titleWithSuffix)

    const rem = selected.length - used.length
    const remText = rem > 0 && `${rem} other${rem > 1 ? 's' : ''}`

    const combined = [...used, remText].filter(x => !!x)
    const res = toEnglishList(combined)

    return res
  }

  const catText = formatCategories()

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">
          {prefix} <strong>{catText}</strong>
          <br />
          {' in '}
          <strong>{store.search.locationSummary}</strong>
        </Typography>
      </Box>
    </Box>
  )
})

const Status = observer(({ classes, search, params }) => {
  if (!params.location) return null

  if (search.loading)
    return (
      <ActionContainer>
        <CircularProgress size={32} thickness={3.0} />
      </ActionContainer>
    )

  if (search.error)
    return (
      <ActionContainer>
        <Typography variant="body1">There was an error fetching results.</Typography>
        <br />

        <Button className={classes.loadMore} variant="outlined" onClick={search.tryAgain}>
          Try Again
        </Button>
      </ActionContainer>
    )

  if (search.canLoadMore)
    return (
      <ActionContainer>
        <Button className={classes.loadMore} variant="outlined" onClick={search.loadMore}>
          Load More
        </Button>
      </ActionContainer>
    )

  return (
    <ActionContainer>
      <Typography variant="body1">
        {search.results.length} Result{search.results.length > 1 && 's'} Found
      </Typography>
    </ActionContainer>
  )
})

const SiteSupport = observer(({ classes, search }) => {
  const { track } = useAnalytics()

  const handleClickSiteSupport = useCallback(() => {
    track('searchResultsButtonClick', {
      category: 'SearchResults',
      label: 'Site Support',
    })
  }, [track])

  if (search.loading) return null

  return (
    <ActionContainer>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Can&apos;t find what your looking for?
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        to="/support"
        component={ReactRouterLink}
        onClick={handleClickSiteSupport}
      >
        <Box mr={1}>
          <i className="fas fa-user-headset" />
        </Box>
        Contact Site Support
      </Button>
    </ActionContainer>
  )
})

const FavouritesStatus = observer(({ classes, search }) => {
  if (search.results.length === 0)
    return (
      <div className={classes.actionContainer} data-nosnippet>
        <Typography variant="body1" align="center">
          You have no results in your favourites list.
          <br />
          Add items by clicking the <i className="far fa-star" /> icon in the search results.
        </Typography>
      </div>
    )

  return (
    <ActionContainer sx={{ alignItems: 'stretch' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          {search.results.length} Result{search.results.length > 1 && 's'} Found
        </Typography>
      </Box>
    </ActionContainer>
  )
})

const DividerWrapper = ({ classes, ...innerProps }) => (
  <Box px={1}>
    <Divider {...innerProps} />
  </Box>
)

const CovidAlertWrapper = ({ classes, ...innerProps }) => <CovidAlert {...innerProps} />

const SponsorsWrapper = ({ classes, ...innerProps }) => <Sponsors {...innerProps} variant="ie11" />

const AcknowledgementWrapper = ({ classes, ...innerProps }) => <Acknowledgement {...innerProps} />

const SearchResultsMarkdownWrapper = observer(({ search, contentPrefix }) => {
  const { content } = search.control.getSelectedInformation(contentPrefix) || {}
  return <SearchResultsMarkdown content={content} />
})

const componentMap = {
  Welcome,
  Results,
  StatusHeader,
  Status,
  FavouritesStatus,
  SiteSupport,
  Divider: DividerWrapper,
  CovidAlert: CovidAlertWrapper,
  Promotions,
  Sponsors: SponsorsWrapper,
  Acknowledgement: AcknowledgementWrapper,
  SearchResultsMarkdown: SearchResultsMarkdownWrapper,
}

const Sections = ({ sections, commonProps }) => {
  const { classes } = commonProps

  return (
    <>
      {sections.map(({ type, items }, key) => {
        const sectionItems = items
          .map(({ component, theme, props }, key) => {
            const cprops = { ...commonProps, ...props }
            const C = componentMap[component]
            if (!C) {
              console.warn(`Invalid component ${component}`)
              return null
            }

            if (!theme) return <C key={key} {...cprops} />

            return (
              <TenantThemeProvider key={key} theme={theme}>
                <C {...cprops} />
              </TenantThemeProvider>
            )
          })
          .filter(c => !!c)

        return (
          <Box key={key} className={clsx(classes.section, classes[type])}>
            {sectionItems}
          </Box>
        )
      })}
    </>
  )
}

const SearchResultsList = observer(({ classes, searchId }) => {
  const { track } = useAnalytics()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('ph'))

  const store = useStore()
  const { params } = store.search
  const search = store.search.searches.get(searchId)

  const { tenantConfig: tc } = useConfig()
  const srl = tc.ui?.search?.resultsList
  const cfg = srl?.[searchId] || srl?.default

  const rootRef = React.useRef()

  const { selectedResult, setSelectedResult } = useResultsControl({
    subscriber: { searchId, control: 'results' },
  })

  // scroll to top callback

  const scrollToTop = React.useCallback(() => {
    const el = document.getElementById(`search-results-top-${searchId}`)

    scrollIntoView(el, {
      scrollMode: 'always',
      block: 'start',
    })
  }, [])

  // scroll to selected callback

  const scrollToSelected = React.useCallback(() => {
    const { subscriber, result } = selectedResult
    if (subscriber?.control === 'results') return
    if (!result) return

    const el = document.getElementById(`result-${result?.id}`)
    if (!el) return

    scrollIntoView(el, {
      scollMode: 'always',
      behavior: 'smooth',
      block: 'start',
    })
  }, [selectedResult])

  const scrollToSelectedCallbackRef = React.useRef({ callback: undefined })

  React.useEffect(() => {
    scrollToSelectedCallbackRef.current.callback = scrollToSelected
  }, [scrollToSelected, scrollToSelectedCallbackRef])

  // scroll to top on new search

  useEffect(() => {
    scrollToTop()
  }, [store.search.searchCount, searchId, scrollToTop])

  // scroll to selected on selection change

  useEffect(() => {
    scrollToSelected()
  }, [selectedResult, scrollToSelected])

  // scroll to selection on parent mutation (display: none)

  useParentMutationObservers({
    element: rootRef.current,
    mutationObservers: _.compact([
      {
        selector: 'div[role="tabpanel"]',
        onMutation: (...args) => scrollToSelectedCallbackRef?.current?.callback(...args),
      },
      isMobile && {
        selector: 'div[role="resultslist"]',
        onMutation: (...args) => scrollToSelectedCallbackRef?.current?.callback(...args),
      },
    ]),
  })

  // click outside

  const handleClickOutside = useCallback(
    e => {
      track('searchResultsResultClickOutside', {
        category: 'SearchResults',
        label: 'Click Outside',
      })

      e.stopPropagation()
      setSelectedResult()
    },
    [store.search, track]
  )

  const sections = cfg?.sections || []
  const commonProps = { classes, store, search, params }

  return (
    <div ref={rootRef} className={classes.root} onClick={handleClickOutside}>
      <div id={`search-results-top-${searchId}`}></div>
      <Sections searchId={searchId} commonProps={commonProps} sections={sections} />
    </div>
  )
})

export default withStyles(styles, { name: 'AMSSearchResultsList' })(SearchResultsList)
