import React, { useState } from 'react'

import clsx from 'clsx'

import { makeStyles } from '@mui/styles'
import {
  Box,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Link,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  intro: {
    fontSize: 18,
  },
  emergencyButton: {
    fontSize: 20,
    fontWeight: 600,
  },
  grid: {
    marginTop: theme.spacing(4),
  },
  accordion: {
    backgroundColor: '#fafafa',
    margin: theme.spacing(2, 0),
    // boxShadow: 'none',
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.12)',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  groupTitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  groupIcon: {
    marginRight: theme.spacing(2),
  },
  serviceLink: {
    // backgroundColor: '#f0f',
    ...theme.typography.body1,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
  serviceLinkIcon: {
    marginRight: theme.spacing(1),
  },
}))

const ServiceLink = ({ icon, label, href }) => {
  const classes = useStyles()

  return (
    <div className={classes.serviceLink}>
      <Link color="primary" href={href} underline="none" target="_blank">
        <i className={clsx(icon, classes.serviceLinkIcon)} />
        {label}
      </Link>
      {/* {href} */}
    </div>
  )
}

const EmergencyAccordian = ({
  expandId,
  title,
  description,
  phone,
  chatUrl,
  expanded,
  onAccordionClick = () => undefined,
}) => {
  const classes = useStyles()

  const serviceLinkProps = phone
    ? { icon: 'fas fa-phone', label: phone, href: `tel:${phone.replace(/[^\d]/g, '')}` }
    : chatUrl
    ? { icon: 'fas fa-comments', label: 'Chat', href: chatUrl }
    : null

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      expanded={expanded === expandId}
      onChange={() => onAccordionClick(expandId)}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // aria-controls={id}
        id={expandId}
      >
        <Typography variant="h6" className={classes.groupTitle}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        <Typography variant="body1">{description}</Typography>
        {serviceLinkProps && <ServiceLink {...serviceLinkProps} />}
      </AccordionDetails>
    </Accordion>
  )
}

const EmergencyGroup = ({ group, expanded, onAccordionClick = () => undefined }) => {
  const classes = useStyles()

  return (
    <Grid sm={12} md={6} item>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        <i className={clsx(group.icon, classes.serviceLinkIcon)} />
        {group.title}
      </Typography>
      {group.items.map((item, i) => (
        <EmergencyAccordian
          key={i}
          expanded={expanded}
          expandId={`${group.id}-${i}`}
          {...item}
          onAccordionClick={onAccordionClick}
        />
      ))}
    </Grid>
  )
}

const Emergency = ({ data }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState('')
  const groups = data.groups

  const handleAccordionClick = panel => {
    setExpanded(expanded === panel ? undefined : panel)
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        Emergency Help
      </Typography>
      <Divider />
      <br />

      <br />

      <Box textAlign="center">
        <Typography
          variant="body1"
          className={classes.intro}

          // textAlign="center"
        >
          If you or someone you know needs immediate assistance, please call
        </Typography>
        <br />

        <Button
          href="tel:000"
          variant="contained"
          color="primary"
          disableElevation
          className={classes.emergencyButton}
          // fontSize={18}
        >
          Emergency Services 000
        </Button>
      </Box>

      <br />
      <br />

      <Grid spacing={2} container className={classes.grid}>
        {groups.map((group, i) => (
          <EmergencyGroup
            key={i}
            group={group}
            expanded={expanded}
            onAccordionClick={handleAccordionClick}
          />
        ))}
      </Grid>
    </div>
  )
}

export default Emergency
