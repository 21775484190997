import React, { useState } from 'react'

import { toJS } from 'mobx'
import { toJSDeep } from 'data/store/utils/mobx'
import clsx from 'clsx'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { Liquid } from 'liquidjs'

import { useQuery } from 'react-query'
import { Link as ReactRouterLink, Redirect } from 'react-router-dom'
import { minutesToMilliseconds as m2ms } from 'date-fns'

import { withStyles } from '@mui/styles'
import { Box, Breadcrumbs, Button, Grid, Typography, CircularProgress } from '@mui/material'

import { RouterLink } from 'ui/nav/Link'
import SimpleMarkdown from 'ui/markdown/SimpleMarkdown'

import { useMergeOptions } from 'ui/hooks/useMergeOptions'
import { useStore } from 'data/store/store'
import { useRouteStore } from 'data/store/route/RouteStore'
import { queries, browseQuery } from 'data/api/browse'

import RouteQueryHandler from 'ui/router/RouteHandler'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    margin: '40px auto',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
  },
  actionButton: {
    margin: '20px auto',
  },
  items: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  regionLink: {
    textTransform: 'none',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  regionLinkText: {
    flex: '1 0 auto',
  },
  regionLinkIcon: {
    flex: '0 1 auto',
    maxWidth: 32,
    marginRight: 4,
  },
})

const linkIcons = {
  browse: 'fas fa-chevron-right',
  search: 'fas fa-search',
}

const ItemLink = props => {
  const { classes } = props

  // console.log({ props })

  const { item = {}, ...innerProps } = props || {}

  if (!item) {
    console.warn(`Invalid item`)
    return null
  }

  const { to, name, pathType } = item
  const { linkType } = pathType

  const icon = linkIcons[linkType]
  const buttonProps = { to, ...innerProps }

  return (
    <Button
      className={classes.regionLink}
      component={ReactRouterLink}
      variant="outlined"
      color="primary"
      {...buttonProps}
    >
      <Box className={classes.regionLinkText}>{name}</Box>
      <i className={clsx(classes.regionLinkIcon, icon)} />
    </Button>
  )
}

const Title = ({ title }) => {
  return (
    <Typography variant="h6" sx={{ mb: 4 }} gutterBottom>
      {title}
    </Typography>
  )
}

const Items = ({ classes, items }) => {
  return (
    <Box className={classes.items}>
      <Grid container spacing={1}>
        {items.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} lg={4}>
            {/* <RouterLink to={item.to}>{item.name}</RouterLink> */}
            <ItemLink classes={classes} item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

// specifically searchCategories and searchLocalityCategories links
const BottomItems = observer(({ classes, items }) => {
  const [liquid] = useState(new Liquid())

  const citems = _.compact(
    items.map(c => {
      const { withLocation, source, templates: t } = c
      const { location } = source

      if (withLocation && !location) return null

      const nameTemplate = location ? t.withLocation : t.default
      const name = liquid.parseAndRenderSync(nameTemplate, { source })

      return { ...c, name }
    })
  )

  return (
    <Box sx={{ my: 2 }}>
      {citems.map((item, i) => (
        <Box key={i} sx={{ my: 2 }}>
          <ItemLink classes={classes} item={item} />
        </Box>
      ))}
    </Box>
  )
})

const useRouteBreadcrumbs = ({ match }) => {
  // console.log(match)
  if (!match) return null

  // const { params: p } = match

  const params = { ...match.params, regions: 'Regions', categories: 'Categories' }

  const [, bckeys] = match.path.split(/[/:]+/g)

  // console.log(pc)
}

const BrowseBreadcrumbs = ({ classes }) => {
  const route = useRouteStore()
  const { match } = route

  const bcmbs = useRouteBreadcrumbs({ match })
  // const paths = _.dropRightWhile(['regions', state, council, locality], o => !o)

  // const crumbs = paths.map((v, i, arr) => {
  //   return {
  //     to: '/' + _.take(arr, i + 1).join('/'),
  //     label: v === 'regions' ? 'Regions' : v.replaceAll('_', ' '),
  //   }
  // })

  const crumbs = []

  const Crumb = ({ current, to, label }) => {
    if (current) return <Typography>{label}</Typography>
    else return <RouterLink to={to}>{label}</RouterLink>
  }

  // if (crumbs.length < 2) return null
  if (crumbs.length === 0) return null

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        {crumbs.map((c, i) => (
          <Crumb key={i} {...c} current={i === crumbs.length - 1} />
        ))}
      </Breadcrumbs>
      <br />
    </>
  )
}

const StaticContent = ({ classes, type, id }) => {
  const store = useStore()
  const md = store.tenant.getContent({ type, id })

  if (!md?.body) return null

  return <SimpleMarkdown>{md.body}</SimpleMarkdown>
}

const defaultRegionsOptions = {
  regionPrefix: 'regions',
  searchPrefix: 'search',
  categoryPrefix: 'category',
}

const Browse = observer(({ classes, options }) => {
  const opts = useMergeOptions(defaultRegionsOptions, options)

  const store = useStore()
  const route = useRouteStore()

  const params = route.match?.params

  const qid = route.path.pathConfig?.query
  const bquery = queries[qid]

  const qopts = {
    options: opts,
    params,
    categories: store.tenant.categories,
    searchLocation: store.search.params.location,
    paths: route.config.generatedPaths,
    categoriesRoute: route.routeConfigViews?.categoriesRoute,
    routePath: route.path,
  }

  const qkey = bquery?.queryKey(params, qopts)
  const qfn = browseQuery(bquery, qopts)

  const { isLoading, data, error, refetch } = useQuery(qkey, qfn, {
    enabled: !!bquery,
    refetchOnWindowFocus: false,
    cacheTime: m2ms(15),
    staleTime: m2ms(15),
  })

  // console.log({ error })

  const { isValid } = data || {}
  if (isValid === false || error) return <Redirect to="/404" />

  return (
    <Box className={classes.root}>
      {/* <Box>{JSON.stringify({ isValid })}</Box>
      <Box>{JSON.stringify({ error })}</Box> */}
      {/* TODO: Use route generator */}
      {/* <BrowseBreadcrumbs classes={classes} options={opts} {...route.match?.params} /> */}

      {isLoading && <CircularProgress size={32} thickness={3.0} className={classes.progress} />}

      {!isLoading && error && (
        <Box className={classes.actionContainer}>
          <Typography variant="body1">There was an error fetching results.</Typography>
          <Button className={classes.actionButton} variant="outlined" onClick={refetch}>
            Try Again
          </Button>
        </Box>
      )}

      {!isLoading && data && (
        <>
          {data.title && <Title title={data.title} />}
          {data.defaultItems && (
            <>
              <Items classes={classes} items={data.defaultItems} />
              <br />
            </>
          )}
          {data.staticContent && <StaticContent classes={classes} {...data.staticContent} />}
          {data.items && <Items classes={classes} items={data.items} />}
          {data.bottomItems && <BottomItems classes={classes} items={data.bottomItems} />}
        </>
      )}
    </Box>
  )
})

export default withStyles(styles, { name: 'AMSBrowse' })(Browse)
