import React from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { makeStyles } from '@mui/styles'
import {
  Container,
  // Typography,
} from '@mui/material'

import BackButton from 'ui/nav/BackButton'
import Footer from 'ui/footer/Footer'
import Acknowledgement from '../../components/acknowledgement/Acknowledgement'

import { useConfig } from 'config/config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  main: {
    flex: '1 0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(12),
  },
}))

const ContentPage = ({ children, backButton = true }) => {
  const classes = useStyles()
  return (
    <PerfectScrollbar
      className={classes.root}
      id="scroll-content"
      options={{
        minScrollbarLength: 50,
      }}
    >
      <Container maxWidth="md" component="main" className={classes.main}>
        <div id="content-page-top" />
        {backButton && <BackButton />}
        {children}
      </Container>
      {/* {!!isAcknowledgement && <Acknowledgement />} */}

      <Footer />
    </PerfectScrollbar>
  )
}

export default ContentPage
