import React, { useCallback, useEffect, useState } from 'react'

// import { inspect } from '@xstate/inspect'

// inspect({
//   url: 'https://statecharts.io/inspect',
//   iframe: false,
// })

import { toJS } from 'mobx'
import { toJSDeep } from 'data/store/utils/mobx'
import { observer } from 'mobx-react-lite'

import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import { useInterpret, useActor } from '@xstate/react'
import { useQueryClient } from 'react-query'

import { routeMachine } from 'data/store/route/route-machine'
import { cacheQueryResults, querySearchUrlParams } from 'data/store/route/parse-url-query'
import { getUrlHash } from 'data/store/utils/hash'

// import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'
import { useRouteStore } from 'data/store/route/RouteStore'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Mono, MonoFa, Json } from 'ui/debug/Mono'

// const serviceLogging = service =>
//   service
//     ?.onTransition(state => {
//       console.log(`%`, state.value)
//       // console.log(state.context)
//       const { router, store, current, redirect } = state.context
//       console.log({ router, store, current, redirect })
//     })
//     .onEvent(event => console.log(`@ ${event.type}`))

const RouteQueryHandler = observer(() => {
  const history = useHistory()
  const match = useRouteMatch()

  const store = useStore()
  const route = useRouteStore()

  const queryClient = useQueryClient()

  const storeInfo = route.path.searchStoreInfo || {}

  const service = useInterpret(routeMachine, {
    actions: {
      redirect: ({ redirect }) => {
        if (!redirect) return
        console.log(`redirect -> ${redirect.url}`)
        history.push(redirect.url)
      },
      // cacheQueryResultsFromStore: ({ store }) => {
      //   // const { params, parameterMap, source } = toJSDeep(route.path.searchStoreInfo)
      //   // const parameterMap = toJSDeep(route.config?.parameterMaps?.search)
      //   const { parameterMap, params, source } = store
      //   const storeParamInfo = route.path.getQueryParamInfo({ parameterMap, params })

      //   cacheQueryResults({ storeParamInfo, params, source, queryClient })
      // },
      writeQueryResultToStore:
        (c, { data = {} }) =>
        // store.search.params.updateFromRouterHandler(data),
        () =>
          console.log('DATA', data),
    },
    services: {
      queryRouteParams: async ({ router, store }) => {
        const parameterMap = toJSDeep(route.config?.parameterMaps?.search)

        const [routerParamInfo, storeParamInfo] = [router, store].map(({ params }) =>
          route.path.getQueryParamInfo({ parameterMap, params })
        )

        return await querySearchUrlParams({
          parameterMap,
          routerParamInfo,
          storeParamInfo,
          router,
          store,
          queryClient,
        })
      },
    },
  })

  const [state, send] = useActor(service)

  useEffect(() => {
    // console.log('effect match.url', { match })
    const { path, url, params } = match
    const hash = getUrlHash({ url, params })
    send({ type: 'SET_ROUTER_INFO', urlInfo: toJSDeep({ path, url, params, hash }) })
  }, [match.url])

  // useEffect(() => {
  //   // console.log('effect storeInfo.hash', { urlInfo: toJSDeep(storeInfo) })
  //   if (!storeInfo) return
  //   send({ type: 'SET_STORE_INFO', urlInfo: toJSDeep(storeInfo) })
  // }, [storeInfo?.hash])

  // return <></>
  return null
})

export default RouteQueryHandler
