import React from 'react'

import _ from 'lodash'

import { withStyles } from '@mui/styles'
import { Container, Typography, Divider, Grid, Link as MuiLink } from '@mui/material'

// import { withVariantStyles } from 'ui/hooks/theme-hooks'

import StaticMarkdown from 'ui/markdown/StaticMarkdown'
import StaticMenu from 'ui/nav/menus/StaticMenu'
import ImageList from 'ui/images/ImageList'
import CieBlock from 'ui/content/CieBlock'
import Sponsors from 'ui/content/Sponsors'
import Acknowledgement from 'ui/content/Acknowledgement'

const footerComponents = {
  StaticMarkdown,
  CieBlock,
  StaticMenu,
  Sponsors,
  Acknowledgement,
}

import { useConfig } from 'config/config'

const { tenantConfig } = useConfig()

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.primary,
  },
  container: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  sectionTitleLabel: {
    fontSize: 15,
    fontWeight: 600,
  },
  divider: {},
  block: {
    padding: theme.spacing(2),
  },
  blockTitle: {
    marginBottom: theme.spacing(4),
  },
  blockTitleLabel: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  blockContents: {},
  acknowledgeText: {
    margin: '15px',
  },
})

const FooterTitle = ({ classes, label, divider = true }) => {
  if (!label) return null
  return (
    <div className={classes.title}>
      <Typography
        className={classes.label}
        variant="h6"
        // gutterBottom
      >
        {label}
      </Typography>
      {divider && <Divider className={classes.divider} />}
    </div>
  )
}

const FooterBlock = ({ classes, title, component, gridProps = { xs: 12 }, props }) => {
  const titleClasses = {
    title: classes.blockTitle,
    label: classes.blockTitleLabel,
    divider: classes.divider,
  }
  const C = footerComponents[component]

  return (
    <Grid item className={classes.block} {...gridProps}>
      {title && title.label && <FooterTitle classes={titleClasses} {...title} />}

      {C && <C {...props} />}
    </Grid>
  )
}

const FooterSection = ({ classes, blocks, title }) => {
  const titleClasses = {
    title: classes.sectionTitle,
    label: classes.sectionTitleLabel,
    divider: classes.divider,
  }
  let isAcknowledgement = useConfig()?.tenantConfig?.ui?.acknowledgements

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        {title && title.label && <FooterTitle classes={titleClasses} {...title} />}
        {blocks && (
          <Grid container>
            {blocks.map((block, i) => (
              <FooterBlock key={i} classes={classes} {...block} />
            ))}
          </Grid>
        )}
        {isAcknowledgement && (
          <div className={classes.acknowledge}>
            <div className={classes.acknowledgeText}>
              The Mental Health Commission acknowledges Aboriginal and Torres Strait Islander people
              as the Traditional Custodians of this country and its waters. The Commission wishes to
              pay its respects to Elders past and present and extend this to all Aboriginal people
              seeing this message.
            </div>

            <div className={classes.acknowledgeText}>
              The Mental Health Commission would also like to acknowledge the individual and
              collective expertise of those with living or lived experience of mental health,
              alcohol and other drug issues. We recognise the vital contribution you make by sharing
              your unique experience to achieve better outcomes for all.
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default withStyles(styles, { name: 'AMSFooterSection' })(FooterSection)
