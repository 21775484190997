import React from 'react'

import { observer } from 'mobx-react-lite'
import { Link as RouterLink } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import clsx from 'clsx'

import { makeStyles } from '@mui/styles'
import {
  Button,
  Typography,
} from '@mui/material'

import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(1, 0),
      },
    },
  },
  label: {
    fontSize: 15,
    // marginRight: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const PreviousSearch = observer(({ route }) => {
  const store = useStore()
  const classes = useStyles()
  const { track } = useAnalytics()

  if (!store.search.params.location) return null

  const handleClick = () => {
    track('homePageButtonClick', {
      category: 'Navigation',
      label: 'Previous Search',
    })
  }

  return (
    <div className={classes.root}>
      <Typography
        align="center"
        className={classes.label}
        variant="body2"
      >
        Continue to your
      </Typography>

      <Button
        color="primary"
        variant="outlined"
        to="/search"
        size="small"
        component={RouterLink}
        onClick={handleClick}
      >
        <i className={clsx('fas fa-search fa-fw', classes.icon)} />Previous Search
      </Button>

    </div>
  )
})

export default PreviousSearch
