/* item snippet

{
  title: '',
  links: [
    { type: 'web', link: '' },
    { type: 'tel', link: '' },
  ],
  description: '',
},

*/

export const selfHelp = {
  groups: [
    {
      id: 'telephone',
      title: 'National Telephone Help Numbers',
      icon: 'fas fa-phone',
      items: [
        {
          title: 'Suicide Call Back Service',
          links: [{ type: 'tel', link: '1300 659 467' }],
          description:
            'Suicide Call Back Service is a nationwide service that provides professional 24/7 telephone and online counselling to people who are affected by suicide.',
        },
        {
          title: '1800RESPECT',
          links: [{ type: 'tel', link: '1800 737 732' }],
          description:
            'Confidential information, counselling and support service open 24 hours to support people impacted by sexual assault, domestic or family violence and abuse.',
        },
        {
          title: 'QLife',
          links: [{ type: 'tel', link: '1800 184 527' }],
          description: `QLife is an australian first nationally-oriented counselling and referral service for LGBTI people.

          The project provides nation-wide, early intervention, peer supported telephone and web based services to diverse people of all ages experiencing poor mental health, psychological distress, social isolation, discrimination, experiences of being misgendered and/or other social determinants that impact on their health and wellbeing.`,
        },
        {
          title: 'eheadspace',
          links: [{ type: 'tel', link: '1800 650 890' }],
          description: `Free online and telephone service that support young people aged between 12 and 25 and their families going through a tough time.

          7 days a week 9am to 1am AEDST. Provides free online and telephone support and counselling to young people 12-25 and their families and friends going through a tough time.
          `,
        },
        {
          title: 'Beyond Blue',
          links: [{ type: 'tel', link: '1300 224 636' }],
          description: `If you need support, we’re here for you.

          All calls and chats are one-on-one with a trained mental health professional, and completely confidential. 24/7.

          If you are deaf and/or find it hard hearing or speaking with people who use a phone you can contact Beyond Blue using the National Relay Service on 1300 555 727.
          `,
        },
        {
          title: 'Lifeline',
          links: [{ type: 'tel', link: '13 11 14' }],
          description:
            '13 11 14 is a confidential telephone crisis support service available 24/7 from a landline, payphone or mobile. Anyone across Australia experiencing a personal crisis or thinking about suicide can contact lifeline. Regardless of age, gender, ethnicity, religion, or sexual orientation our trained volunteers are ready to listen, provide support and referrals.',
        },
        {
          title: 'Kids Helpline',
          links: [{ type: 'tel', link: '1800 55 1800' }],
          description:
            'A free, private and confidential, telephone and online counselling service specifically for young people aged between 5 and 25.',
        },
        {
          title: 'Carers Australia',
          links: [{ type: 'tel', link: '1800 242 636' }],
          description:
            'Short-term counseling and emotional and psychological support services for carers and their families in each state and territory.',
        },
        {
          title: 'MensLine',
          links: [{ type: 'tel', link: '1300 78 99 78' }],
          description:
            'Short-term counseling and emotional and psychological support services for carers and their families in each state and territory.',
        },
        {
          title: 'MindSpot Clinic',
          links: [{ type: 'tel', link: '1800 614 434' }],
          description:
            'An online and telephone clinic providing free assessment and treatment service for Australian adults with anxiety or depression.',
        },
        {
          title: 'SANE Australia',
          links: [{ type: 'tel', link: '1800 187 263' }],
          description:
            'Information about mental illness, treatment, where to go for support and help carers.',
        },
        {
          title: 'Relationships Australia',
          links: [{ type: 'tel', link: '1300 364 277' }],
          description:
            'A provider of relationship support service for individuals, families and communities.',
        },
        {
          title: 'GriefLine',
          links: [{ type: 'tel', link: '1300 845 745' }],
          description:
            'Phone and online counselling for individuals and families experiencing loss and grief.',
        },
        {
          title: 'Diverse Voices',
          links: [{ type: 'tel', link: '1800 184 527' }],
          description:
            'Diverse Voices is a peer-to-peer telephone, web chat, referral service for LGBTQI+ people and their family and friends. This service operates from 3pm to midnight 7 days a week.',
        },
        {
          title: 'Open Arms - Vetrans & Family Counselling',
          links: [{ type: 'tel', link: '1800 011 046' }],
          description:
            'Provides confidential phone support 24 hours a day, 7 days a week to all current and ex-serving personnel and their families.',
        },
        {
          title: 'Quitline',
          // label
          links: [{ type: 'tel', link: '13 78 48' }],
          description: 'Offers support, encouragement and resources to help you quit smoking.',
        },
        {
          title: 'Cannabis Information & Support',
          links: [{ type: 'tel', link: '1800 304 050' }],
          description:
            'Is an information and support service for cannabis users and their family and friends.',
        },
        {
          title: 'ADF DrugInfo',
          links: [{ type: 'tel', link: '1300 858 584' }],
          description:
            'A free and confidential service to speak to a real person for real information about alcohol and other drugs.',
        },
        {
          title: 'Family Drug Support Australia',
          links: [{ type: 'tel', link: '1300 368 186' }],
          description:
            'A free and confidential telephone support line providing 24/7 support for family members affected by drugs and alcohol.',
        },
        {
          title: 'Translating And Interpreting Service',
          links: [{ type: 'tel', link: '131 450' }],
          description:
            'For people who do not use English as their first language can get free translation support.',
        },
      ],
    },
    {
      id: 'apps',
      title: 'Apps',
      icon: 'fas fa-mobile-alt',
      items: [
        {
          title: 'Daybreak - Alcohol Support',
          apps: [
            {
              type: 'ios',
              url: 'https://apps.apple.com/au/app/daybreak-alcohol-support/id1107514492',
            },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=com.hellosundaymorning.android.challenges',
            },
          ],
          description:
            'Daybreak is an online program by Hello Sunday Morning that uses habit change experiments, one-on-one coaching and a supportive community to address alcohol use.',
        },
        {
          title: 'MoodMission',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/moodmission/id1140332763' },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=com.moodmission.moodmissionapp',
            },
          ],
          description:
            'A free app designed to help individuals overcome low moods and anxiety by discovering new coping strategies.',
        },
        {
          title: 'MindGuage',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/mindgauge/id1091408335' },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=au.edu.sydney.poscomp.mindgaugev2',
            },
          ],
          description:
            'A free app that allows users to easily and quickly track their mental health, mood, and lifestyle.',
        },
        {
          title: 'BeyondNow',
          apps: [
            {
              type: 'ios',
              url: 'https://apps.apple.com/au/app/beyondnow-suicide-safety-plan/id1059270058',
            },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=au.org.beyondblue.beyondnow',
            },
          ],
          description:
            'An app and online tool that helps users create a safety plan for when experiencing suicidal thoughts.',
        },
        {
          title: 'ReachOut Breathe',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/reachout-breathe/id985891649' },
          ],
          description:
            'A free app (iOS only) to help reduce the physical symptoms of anxiety by slowing down breathing and heart rate.',
        },
        {
          title: 'Smiling Mind',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/smiling-mind/id560442518' },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=com.smilingmind.app',
            },
          ],
          description:
            'A website and app teaching mindfulness meditation to young people and adults.',
        },
        {
          title: 'iBobbly',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/ibobbly/id1478592523' },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=au.org.blackdoginstitute.ibobblyapp',
            },
          ],
          description:
            'Wellbeing and suicide prevention self-help app for young Aboriginal and Torres Strait Islander Australians.',
        },
        {
          title: 'Niggle',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/niggle/id1397384022' },
            { type: 'android', url: 'https://play.google.com/store/apps/details?id=com.niggle' },
          ],
          description:
            'A free app for youth designed self-help toolkit for all things related to mental, social and emotional wellbeing.',
        },
        {
          title: 'Check-in',
          apps: [
            { type: 'ios', url: 'https://apps.apple.com/au/app/check-in-beyond-blue/id881323078' },
            {
              type: 'android',
              url: 'https://play.google.com/store/apps/details?id=au.com.beyondblue.checkin&hl=en_AU&gl=US',
            },
          ],
          description:
            'An app to help young people take the fear out of having a conversation with a friend who might be struggling.',
        },
      ],
    },

    {
      id: 'online',
      title: 'Online Programs',
      icon: 'far fa-globe',
      items: [
        {
          title: 'eFriend',
          links: [{ type: 'web', link: 'https://efriend.org.au' }],
          description:
            'Efriend virtual peer support connects you to a community of trained, specialist efriend peer via video or audio call.',
        },
        {
          title: 'SMART Recovery',
          links: [{ type: 'web', link: 'https://smartrecoveryaustralia.com.au' }],
          description:
            'SMART Recovery is the self-management and recovery training recovery is a free group program assisting any problematic behaviors including addiction to drugs, alcohol, cigarettes, gambling, food, shopping, internet and others. Participants come to help themselves and each other.',
        },
        {
          title: 'Alcoholics Anonymous',
          links: [{ type: 'web', link: 'https://aa.org.au' }],
          description:
            'Alcoholics Anonymous meetings provides a 12-step framework to recovery from alcohol use.',
        },
        {
          title: 'Narcotics Anonymous',
          links: [{ type: 'web', link: 'https://www.na.org.au/multi' }],
          description:
            'Narcotics Anonymous meetings are a gathering of people who meet for the purpose of recovery from addiction.',
        },
        {
          title: 'Counselling Online',
          links: [{ type: 'web', link: 'https://www.counsellingonline.org.au' }],
          description:
            'Communicate online with a professional counsellor about any alcohol or drug related concern, avalible 24/7. This service is free for anyone seeking help with their own use or the use of a family member, relative or friend. You can choose to remain anonymous or to register with a service.',
        },
        {
          title: 'REACHOUT',
          links: [{ type: 'web', link: 'https://au.reachout.com' }],
          description:
            'Contains lots of useful information on different drugs. You can print out fact sheets, which are written in plain English.',
        },
        {
          title: 'headspace',
          links: [{ type: 'web', link: 'https://www.headspace.org.au' }],
          description:
            'Free online and telephone service that support young people aged between 12 and 25 and their families going through a tough time.',
        },
        {
          title: 'OnTrack Alcohol',
          links: [{ type: 'web', link: 'https://ontrack.org.au/web/ontrack/programs/alcohol' }],
          description:
            'OnTrack Alcohol aims to help people cut back on their drinking. The program was written by psychologists and uses methods that have been shown in research to be helpful. Who is OnTrack Alcohol for? This program is for people with risky levels of drinking.',
        },
        {
          title: 'ASSIST PORTAL',
          links: [{ type: 'web', link: 'https://www.assistportal.com.au' }],
          description:
            'The ASSIST and the ASSIST-Lite are available in electronic format. The EAssist and the EAssist-Lite are web based versions that can be used on a personal computer. The ASSIST Checkup and ASSIST Checkup Lite are downloadable apps for completion on any smartphone or tablet.',
        },
        {
          title: 'adis Self Assessment Quizzes',
          links: [
            { type: 'web', link: 'https://adis.health.qld.gov.au/getting-support/self-assessment' },
          ],
          description:
            'Queenslanders can also take a self-assessment quiz on the Adis website to help them figure out how much they’re drinking and how they are affected by their alcohol intake. After the quiz, they have the option of using Adis’s support services if they want help managing their relationship with alcohol.',
        },
        {
          title: 'Black Dog Institute',
          links: [{ type: 'web', link: 'https://www.blackdoginstitute.org.au' }],
          description:
            'BDI is proud to partner with some of Australia’s leading brands in creating a mentally healthier world. Researching the early detection, prevention and treatment of common mental health disorders. Key Research Areas. Suicide prevention. Digital mental health. Workplace mental health. Youth mental health. Treatments & models of care.',
        },
        {
          title: 'PANDA',
          links: [{ type: 'web', link: 'https://www.panda.org.au' }],
          description:
            'PANDA - Perinatal Anxiety & Depression Australia supports women, men and families across Australia affected by anxiety and depression during pregnancy and in the first year of parenthood. PANDA operates Australia’s only National Helpline for individuals and their families to recover from perinatal anxiety and depression, a serious illness that affects up to one in five expecting or new mums and one in ten expecting or new dads.',
        },
        {
          title: 'Butterfly Foundation',
          links: [{ type: 'web', link: 'https://butterfly.org.au' }],
          description:
            'The Butterfly Foundation represents all people affected by eating disorders – a person with an eating disorder, their family and their friends. As a leading national voice in supporting their needs, Butterfly highlights the realities of seeking treatment for recovery, and advocates for improved services from both government and independent sources. Butterfly operates a National Helpline, which is staffed by counsellors experienced in assisting with eating disorders. It also provides a wide range of facilities for service providers and recovery groups. Because Butterfly recognises that eating disorders often arise from poor body image, it delivers a range of Positive Body Image workshops to schools and workplaces through its education program. It has a strong media presence to raise awareness of Butterfly’s perspective in community debates about body image and eating disorders.',
        },
        {
          title: 'R U OK?',
          links: [{ type: 'web', link: 'https://www.ruok.org.au' }],
          description:
            'R U OK? is an Australian non-profit suicide prevention organisation, founded by advertiser Gavin Larkin in 2009. It revolves around the slogan "R U OK?", and advocates for people to have conversations with others. The organisation has a dedicated R U OK? Day, held annually on the second Thursday of September, which encourages Australians to connect with people who have emotional insecurity, to address social isolation and promote community cohesiveness.',
        },
        {
          title: 'Head to Health',
          links: [{ type: 'web', link: 'https://headtohealth.gov.au' }],
          description:
            'Head to Health can help you find digital mental health services from some of Australia’s most trusted mental health organizations. Provided by the Australian Department of Health, Head to Health brings together apps, online programs, online forums, and phone services, as well as a range of digital information resources. Whether you are trying to improve your own sense of wellbeing, looking for help with something that is bothering you, or helping someone you care about-Head to Health is a good place to start.',
        },
        {
          title: 'MindSpot',
          links: [{ type: 'web', link: 'https://mindspot.org.au' }],
          description:
            "MindSpot Clinic is Australia's first free national online mental health clinic which launched in December 2012. It provides screening assessments and internet-delivered cognitive behavioral therapy courses for Australians troubled by stress, worry, anxiety and depression. Helping people aged between 26-65.",
        },
        {
          title: 'Mental Health Online',
          links: [{ type: 'web', link: 'https://www.mentalhealthonline.org.au' }],
          description:
            'If you feel like you could use a little extra support with any of the Mental Health Online Programs, you can sign up for the Therapist Assisted Program.The Therapist Assisted Program gives you 12 weeks of therapist support via weekly emails and up to 4 live sessions via video or instant chat to help you to tailor the program material to your specific situation, answer any questions you may have along the way, and support you to stay on track with the program.',
        },
        {
          title: 'e-couch',
          links: [{ type: 'web', link: 'https://ecouch.com.au' }],
          description:
            'An updated version of the e-Couch for Social Anxiety program is now available at ecouch.com.au. The new program is user friendly and can be viewed on devices with different sized screens. Looking for Social Anxiety self-help? Go to the updated program at ecouch.com.au. Updates to the other e-Couch programs will be released in the coming months.',
        },
      ],
    },
  ],
}
