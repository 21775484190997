import React from 'react'
import _ from 'lodash'

import { Link as RouterLink } from 'react-router-dom'

import { withStyles } from '@mui/styles'

import { useConfig } from 'config/config'
import { withVariantStyles } from 'ui/theme/TenantTheme'

export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // margin: theme.spacing(-1, -1),
    // border: '1px solid #f00',
  },
  imageContainer: {
    maxHeight: 60,
    maxWidth: 120,
    // margin: theme.spacing(1, 1),
    '& img': {
      height: '100%',
      // width: '100%',
      maxHeight: 'inherit',
      maxWidth: 'inherit',
      // margin: '0 auto',
      // border: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  link: {},
})

const ImageList = ({ classes, images = [] }) => {
  const items = images.map(({ outletId, src, alt, href, to, target }, ikey) => {
    const image = (
      <img
        // className={classes.image}
        // height="50px"
        data-outletid={outletId}
        src={src}
        alt={alt || 'sponsor logo'}
      />
    )

    if (href)
      return (
        <a key={ikey} className={classes.imageContainer} href={href} target={target || '_blank'}>
          {image}
        </a>
      )

    if (to)
      return (
        <RouterLink className={classes.imageContainer} key={ikey} to={to}>
          {image}
        </RouterLink>
      )

    return (
      <div key={ikey} className={classes.imageContainer}>
        {image}
      </div>
    )
  })

  return <div className={classes.root}>{items}</div>
}

// export default withVariantStyles(styles,{ name: 'AMSImageList' })(ImageList)
export default withStyles(styles, { name: 'AMSImageList' })(ImageList)
