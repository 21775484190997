import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
// import * as serviceWorker from './serviceWorker'

// import { RecoilRoot } from 'recoil'
import { Provider as JotaiProvider } from 'jotai'
import { QueryClient, QueryClientProvider } from 'react-query'

import Webfont from 'webfontloader'

import { AnalyticsProvider } from 'use-analytics'
import { analytics } from 'data/analytics/analytics'

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { StylesProvider } from '@mui/styles'

import { useTenantThemes } from 'ui/theme/TenantTheme'

import 'tenants/shared/fontawesome'

import 'tenants/shared/styles/perfect-scrollbar.scss'
import 'tenants/shared/styles/rcslider.scss'

import App from './ui/_main/App'

// eslint-disable-next-line no-undef
const tenantConfig = require(`tenants/${process.env.TENANT}/config`).default

if (tenantConfig.fonts) {
  Webfont.load(tenantConfig.fonts)
}

const Root = () => {
  const queryClient = new QueryClient()
  const themes = useTenantThemes()

  return (
    // <RecoilRoot>
    <JotaiProvider>
      <AnalyticsProvider instance={analytics}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <StylesProvider>
              <ThemeProvider theme={themes.base}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </StylesProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </AnalyticsProvider>
    </JotaiProvider>
    // </RecoilRoot>
  )
}

ReactDOM.render(<Root />, document.querySelector('#root'))
// serviceWorker.unregister()
