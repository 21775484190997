import { types } from 'mobx-state-tree'

export const Geography = types
  .model({
    type: types.literal('geography'),
    id: 0,
    default: false,
    label: '',
    dataset: '',
    area: 0.0,
    lat: 0.0,
    lng: 0.0,
    kmlUrl: '',
    lgas: types.array(types.number),
  })

  .views(self => ({
    get geometry() {
      return {
        lat: self.lat,
        lng: self.lng,
      }
    },

    get adminBoundaryUrl() {
      return self.kmlUrl
        ?.replace(/devdirectorycdn/, 'mcdcdn')
        .replace(/\/kml\//, '/geojson-simple/')
        .replace(/\.kml$/, '.geojson')
    },
  }))
