import { getCieQueryDef } from 'data/api/cie-queries'

import _ from 'lodash'

const queryConfigs = {
  locality: {
    query: 'locality',
    cacheFromStore: true,
  },
  categories: {
    query: 'categories',
  },
}

const getQueryDefs = ({ paramInfo }) => {
  // console.log('getQueryDefs', { paramInfo })
  return _.map(paramInfo.bySource, (params, sk) => {
    const config = queryConfigs[sk]
    if (!config) throw `Invalid Query Config ${sk}`

    const mparams = _.mapKeys(params, (v, pk) => config?.paramMap?.[pk] || pk)

    return {
      _sourceKey: sk,
      _queryConfig: config,
      ...getCieQueryDef(config, mparams, null),
    }
  })
}

const getQueries = ({ queryDefs, queryClient }) =>
  queryDefs.map(({ queryKey, queryFn, select = data => data, ...options }) => async () => {
    const res = await queryClient.fetchQuery(queryKey, queryFn, {
      ..._.omit(options, ['_sourceKey', '_queryConfig']),
      staleTime: 'Infinity',
      cacheTime: 'Infinity',
    })

    return select(res)
  })

export const cacheQueryResults = ({ storeParamInfo, params, source, queryClient }) => {
  try {
    // console.log('cacheQueryResults', { storeParamInfo, params, source })
    const queryDefs = getQueryDefs({ paramInfo: storeParamInfo }).filter(
      qd => qd._queryConfig.cacheFromStore
    )

    queryDefs.forEach(({ _sourceKey, queryKey }) => {
      if (!data) return
      const data = source[_sourceKey]

      console.log('caching query data', queryKey, data)
      queryClient.setQueryData(queryKey, data)
    })
  } catch (e) {
    console.warn(e)
  }
}

export const querySearchUrlParams = async ({
  parameterMap,
  routerParamInfo,
  storeParamInfo,
  router,
  store,
  queryClient,
}) => {
  // console.log('----------')
  // console.log('querySearchUrlParams', {
  //   parameterMap,
  //   routerParamInfo,
  //   storeParamInfo,
  //   router,
  //   store,
  // })

  try {
    const queryDefs = getQueryDefs({ paramInfo: routerParamInfo })
    const fetches = getQueries({ queryDefs, queryClient })

    const queryResults = await Promise.all(fetches.map(f => f()))
    const data = _.fromPairs(queryDefs.map((qd, qdi) => [qd._sourceKey, queryResults[qdi]]))

    // console.log({ data })
    return { isSuccess: true, data }
  } catch (error) {
    return { isSuccess: false, error }
  }
}
