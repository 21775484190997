export const hashJoaat = str => {
  for (var a = 0, c = str.length; c--; ) (a += str.charCodeAt(c)), (a += a << 10), (a ^= a >> 6)
  a += a << 3
  a ^= a >> 11
  return (((a + (a << 15)) & 4294967295) >>> 0).toString(16)
}

export const getUrlHash = ({ url, params = {}, query }) => {
  const urlstr = [url, query].filter(s => !!s).join('_')
  const hash = hashJoaat(urlstr)
  // console.log(urlstr, hash)
  return hash
}
