import React, { useState, useEffect } from 'react'

import { useLocation, useRouteMatch, Redirect } from 'react-router-dom'
import { toJSDeep } from 'data/store/utils/mobx'
import { observer } from 'mobx-react-lite'
import keyBy from 'lodash/keyBy'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@mui/styles'
import { Box, Card, Slide, Button, useMediaQuery } from '@mui/material'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'
import SurveyStep from './SurveyStep'
import _ from 'lodash'

const { tenantConfig } = useConfig()
// const variant = 'self'
// const steps = tenantConfig.survey.pathways[variant].steps
const questions = keyBy(tenantConfig.survey.questions, 'id')

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  slide: {
    minHeight: 400,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
}))

const useQueryParams = location => {
  try {
    const search = location.search.substring(1)
    if (!search) return {}

    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      (key, value) => {
        return key === '' ? value : decodeURIComponent(value)
      }
    )
  } catch {
    return {}
  }
}

const SurveyButtonIcon = ({ icon }) => (
  <Box as="i" className={icon} sx={{ fontSize: '0.86rem !important' }} />
)

const Survey = observer(({ steps }) => {
  const { track } = useAnalytics()
  const classes = useStyles()

  const store = useStore()
  const survey = store.survey

  const [nav, setNav] = useState({ prev: null, next: null })
  const [slideIn, setSlideIn] = useState(true)
  const [slideDirection, setSlideDirection] = useState('left')

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const slideToStep = step => {
    const directions = step < survey.step ? ['left', 'right'] : ['right', 'left']

    setSlideDirection(directions[0])
    setSlideIn(false)

    document.getElementById('scroll-content').scrollTop = 0

    setTimeout(() => {
      survey.set('step', step)
      setSlideDirection(directions[1])
      setSlideIn(true)
    }, 500)
  }

  const handleNavigateStep = (step, label) => () => {
    track(`surveyStepClick`, {
      category: 'Survey',
      label: label,
    })

    if (typeof step === 'number') {
      slideToStep(step)
      return
    }

    if (typeof step === 'string' && step === 'finish') {
      survey.finish()
      return
    }
  }

  // const handleEvalClick = () => {
  //   survey.dump()
  // }

  const handleResetClick = () => {
    track('surveyRestart', {
      category: 'Survey',
      label: 'Restart',
    })

    survey.init(survey.pathway)
  }

  // update nav buttons

  useEffect(() => {
    const prev =
      survey.prevStep !== null
        ? {
            label: 'Go Back',
            step: survey.prevStep,
          }
        : null

    const next =
      survey.nextStep !== null
        ? {
            label: 'Next',
            step: survey.nextStep,
            disabled: !survey.currentStepValid,
          }
        : {
            label: 'Finish',
            step: 'finish',
            disabled: false,
          }

    setNav({ prev, next })
  }, [survey.step, survey.prevStep, survey.nextStep, survey.currentStepValid])

  // entry actions

  useEffect(() => {
    const step = survey.currentStep
    if (!step) return

    const entryActions = step.entry?.actions
    if (!_.isArray(entryActions)) return

    entryActions.forEach(a => survey[a]?.())
  }, [survey.step])

  const contents = (
    <Box>
      <Slide sx={{ minHeight: 400 }} in={slideIn} direction={slideDirection}>
        <div>
          <SurveyStep
            pathway={survey.pathway}
            questions={questions}
            stepConfig={survey.currentStep}
          />
        </div>
      </Slide>
      <Box
        sx={{
          mt: { xs: 2, sm: 4 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          color="default"
          startIcon={<SurveyButtonIcon icon="fas fa-undo" />}
          onClick={handleResetClick}
          sx={{ mr: 2 }}
        >
          Restart
        </Button>

        {/* <Button variant="contained" color="primary" onClick={handleEvalClick}>
          Eval
        </Button> */}

        {nav.prev && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SurveyButtonIcon icon="fas fa-arrow-left" />}
            onClick={handleNavigateStep(nav.prev.step, nav.prev.label)}
          >
            {nav.prev.label}
          </Button>
        )}

        {nav.next && (
          <Button
            disabled={nav.next.disabled}
            variant="contained"
            color="primary"
            endIcon={<SurveyButtonIcon icon="fas fa-arrow-right" />}
            onClick={handleNavigateStep(nav.next.step, nav.next.label)}
          >
            {nav.next.label}
          </Button>
        )}
      </Box>
    </Box>
  )

  return isMobile ? <Box sx={{ p: 1 }}>{contents}</Box> : <Card sx={{ p: 3 }}>{contents}</Card>
})

const SurveyEntry = observer(() => {
  const store = useStore()
  const survey = store.survey

  const redirectRoute = survey.pathwayConfig?.result?.finishRedirect || '/404'
  const redirect = survey.finished && { to: redirectRoute }

  if (redirect) return <Redirect {...redirect} />

  return <Survey steps={survey.steps} />
})

export default SurveyEntry
