import React from 'react'
import { observer } from 'mobx-react-lite'

import SimpleMarkdown from 'ui/markdown/SimpleMarkdown'
import SupportForm from 'ui/pages/support/SupportForm'

import { useStore } from 'data/store/store'
// import StaticMarkdown from 'ui/components/molecules/StaticMarkdown'
import { Box } from '@mui/material'
import { useRouteStore } from 'data/store/route/RouteStore'

const Help = observer(props => {
  const store = useStore()
  const route = useRouteStore()
  const content = store.search.getStaticContent(props.contentId)

  return (
    <div>
      <Box mb={4}>
        <SimpleMarkdown>{content.body}</SimpleMarkdown>
      </Box>

      <SupportForm />
    </div>
  )
})

export default Help
