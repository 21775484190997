import React from 'react'

import { withStyles } from '@mui/styles'
import { Box, Typography, Button } from '@mui/material'

import { useConfig } from 'config/config'

const styles = theme => ({
  root: {
  },
})

const HealthEngineAppointment = ({ classes, config, data }) => {
  const { tenantConfig: tc, appConfig } = useConfig()

  if (!data.healthEngineAppointment) return null

  const url = `${appConfig.directoryURL}/redirect?action=healthengine&outletId=${data.outletId}`

  const BookButton = props => (
    <Button
      classes={{
        root: classes.healthEngineButton,
        label: classes.healthEngineButtonLabel,
      }}
      // sx={{ mt: 2 }}
      variant="outlined"
      color="primary"
      href={url}
      target="_blank"
      disableElevation
      {...props}
    >
      Book Now
    </Button>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
        Next Appointment
      </Typography>
      <Box sx={{ width: '100%', mt: 1, display: 'flex', alignItems: 'center' }}>
        <Box mr={2}>
          <i className="fas fa-calendar" />
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1">{data.healthEngineAppointment}</Typography>
        </Box>
        <BookButton sx={{ mt: 0 }} />
      </Box>
      <Box
        sx={{ mt: 3, ml: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography variant="body2" sx={{ mr: 1.5, whiteSpace: 'nowrap' }}>Powered by</Typography>
        <Box component="img" sx={{ width: 116, height: 24 }} src={tc.assets.healthengine.logo} />
      </Box>
    </Box>
  )
}

export default withStyles(styles, { name: 'AMSHealthEngineAppointment' })(HealthEngineAppointment)
