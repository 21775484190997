import React from 'react'

import { atom, useAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'

import deepEqual from 'fast-deep-equal'

import type { Marker, Popup } from 'leaflet'
import type { IResult } from 'data/store/models/types'

export type Subscriber = {
  searchId: string
  control: string
}

export type PopupState = {
  active?: Popup
  closing?: Popup
  withheld?: Popup
}

export type ResultSelection = {
  subscriber?: Subscriber
  // resultId?: string
  result?: IResult
}

export type SelectionTimeout = {
  lastUpdate: number
}

const subscriberFamily = atomFamily((subscriber: Subscriber) => atom(subscriber), deepEqual)

const popupStateAtom = atom<PopupState>({ active: undefined, closing: undefined })

const selectedResultAtom = atom<ResultSelection>({
  subscriber: undefined,
  result: undefined,
})

const selectedResultTimestampAtom = atom<number>(0)

const debouncedSelectAtom = atom(
  get => get(selectedResultAtom),
  (get, set, update: ResultSelection) => {
    const t1 = get(selectedResultTimestampAtom)
    const t2 = Date.now()

    // console.log('timestamps', t1, t2, t2 - t1)
    if (t2 - t1 > 700) set(selectedResultAtom, update)

    set(selectedResultTimestampAtom, t2)
  }
)

export type ResultsControlProps = {
  subscriber: Subscriber
}

export type ResultsControl = {
  popupState: PopupState
  selectedResult: ResultSelection
  setSelectedResult: (result?: IResult) => void
}

export const useResultsControl = (props: ResultsControlProps): ResultsControl => {
  const [subscriber] = useAtom(subscriberFamily(props.subscriber))
  const [popupState] = useAtom(popupStateAtom)
  const [selectedResult, setSelectedResult] = useAtom(selectedResultAtom)

  return {
    popupState,
    selectedResult,
    setSelectedResult: (result?: IResult) => setSelectedResult({ subscriber, result }),
  }
}
