import React, { useState, useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import { Slider } from '@mui/material'

import RCSlider from 'rc-slider'

import { usePlatform } from 'ui/hooks/usePlatform'
import { radiusAtom } from 'ui/_main/AppState'
import { useAtom } from 'jotai'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
  },
  rcroot: {
    margin: theme.spacing(3, 3),
  },
}))

// const rcmarks = [10, 50, 100, 150, 200].reduce((marks, m) => {
//   marks[m] = `${m}km`
//   return marks
// }, {})

const valuetext = value => {
  return `${value} km`
}

const RadiusSlider = ({
  value = 0,
  min = 10,
  max = 200,
  step = 5,
  defaultValue = 75,
  marks = [10, 50, 100, 150, 200],
  onChange = () => undefined,
}) => {
  const platform = usePlatform()

  const sliderProps = { value, min, max, step, defaultValue, marks, onChange }

  if (platform.ieVersion) return <RCRadiusSlider {...sliderProps} />

  return <MuiRadiusSlider {...sliderProps} />
}

// const muiMarks = [10, 50, 100, 150, 200].map(m => ({ value: m, label: `${m}km` }))

const MuiRadiusSlider = ({ value = 0, onChange = () => undefined, marks, ...sliderProps }) => {
  const classes = useStyles()
  const [internalValue, setInternalValue] = useState(0)
  const [radius, setRadius] = useAtom(radiusAtom)

  useEffect(() => {
    setRadius(value)
  }, [value])

  const handleChange = (event, value) => {
    setRadius(value)
  }

  const handleChangeCommitted = (event, value) => {
    onChange(value)
  }

  const muiMarks = marks?.map(m => ({ value: m, label: `${m}km` }))

  return (
    <div className={classes.root}>
      <Slider
        // className={classes.margin}
        getAriaValueText={valuetext}
        aria-labelledby="radius-slider"
        value={radius}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        marks={muiMarks}
        {...sliderProps}
        // defaultValue={75}
        // min={10}
        // max={200}
        // step={10}
      />
    </div>
  )
}

const RCRadiusSlider = ({ value = 0, onChange = () => undefined, marks, ...sliderProps }) => {
  const classes = useStyles()
  const [internalValue, setInternalValue] = useState(0)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const handleChange = value => {
    setInternalValue(value)
  }

  const handleChangeCommitted = value => {
    onChange(value)
  }

  const rcMarks = marks.reduce((marks, m) => {
    marks[m] = `${m}km`
    return marks
  }, {})

  return (
    <div className={classes.rcroot}>
      <RCSlider
        value={internalValue}
        onChange={handleChange}
        onAfterChange={handleChangeCommitted}
        marks={rcMarks}
        {...sliderProps}
      />
    </div>
  )
}

export default RadiusSlider
