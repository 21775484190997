import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'
import { useRouteMatch, Redirect } from 'react-router-dom'

import { useStore } from 'data/store/store'
// import { survey } from 'tenants/amh/survey'

// This is a makeshift redirect to search that will set preset store values
// from props defined in route config

const SurveyRedirect = observer(() => {
  const store = useStore()

  const { track } = useAnalytics()
  const { params } = useRouteMatch()
  const { pathway } = params

  const [redirect, setRedirect] = React.useState(null)

  console.log({ pathway, redirect })

  React.useEffect(() => {
    track('surveyStart', {
      category: 'Survey',
      label: pathway,
    })

    store.survey.init(pathway)
    setRedirect({ to: store.survey.pathwayConfig ? '/survey' : '/404' })
  }, [pathway, track])

  if (!redirect) return null

  return <Redirect {...redirect} />
})

export default SurveyRedirect
