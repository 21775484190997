import React from 'react'

import { withStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'

import { withVariantStyles } from 'ui/theme/TenantTheme'
import { useConfig } from 'config/config'

const styles = theme => ({
  root: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'flex-start',
    // [theme.breakpoints.down('sm')]: {
    //   flexDirection: 'column',
    // },
  },
  flag: {
    maxHeight: 30,
    // margin: theme.spacing(0, 0.5),
  },
})

const flagAltText = {
  aboriginal: 'Aboriginal Flag',
  torresStrait: 'Torres Strait Flag',
  lgbt: 'Rainbow Flag',
}

const Flags = ({ classes, flagIds = ['aboriginal', 'torresStrait', 'lgbt'], ...props }) => {
  const { tenantConfig: tc } = useConfig()

  const Flag = ({ src, alt }) => <img className={classes.flag} src={src} alt={alt} />

  const flags = flagIds.map(id => ({ src: tc.assets.flags?.[id], alt: flagAltText[id] }))

  return (
    <Box
      as="a"
      className={classes.root}
      sx={{ mt: 4, display: 'flex', gap: 1 }}
      target="_blank"
      {...props}
    >
      {flags.map((flag, i) => (
        <Flag key={i} {...flag} />
      ))}
    </Box>
  )
}

export default withStyles(styles, { name: 'AMSFlags' })(Flags)
