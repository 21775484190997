import React from 'react'

import _ from 'lodash'
import clsx from 'clsx'
import { withStyles } from '@mui/styles'
import { Box, FormLabel, FormControl, FormControlLabel, Button, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { resolveVariantText } from 'data/store/utils/survey-utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {},
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  // labelRoot: {
  //   alignItems: 'flex-start',
  //   marginBottom: theme.spacing(1),
  // },
  // label: {
  //   marginTop: theme.spacing(1.1),
  // },
  // title: {
  //   marginBottom: theme.spacing(3),
  // },
})

const SurveyQuestion = ({ classes, question, children }) => {
  const {
    icon,
    strings = {},
    hideLabel = false,
    hideDescription = false,
    hideIcon = false,
  } = question || {}

  // const strings = _.mapValues(strings, )

  return (
    <FormControl
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}
      className={classes.root}
    >
      {!hideLabel && strings.label && (
        <FormLabel sx={{ mb: 2 }} className={classes.label}>
          {!hideIcon && icon && <i className={clsx(icon, classes.labelIcon)} />}
          {strings.label}
        </FormLabel>
      )}

      {!hideDescription && strings.description && (
        <Typography variant="body1" gutterBottom>
          {strings.description}
        </Typography>
      )}

      {/* {!hide} */}

      {children && <Box sx={{ my: 2 }}>{children}</Box>}

      {/* SurveyQuestion */}
      {/* <FormLabel>{questionText}</FormLabel>
      <br />
      <Box>
        {buttons.map(({ label, icon, ...buttonProps }, i) => {
          return (
            <Button
              key={i}
              component={buttonProps?.to && RouterLink}
              startIcon={icon && <i className={icon} />}
              {...buttonProps}
            >
              {label}
            </Button>
          )
        })}
      </Box> */}
    </FormControl>
  )
}

export default withStyles(styles, { name: 'AMSSurveyQuestion' })(SurveyQuestion)
