import React from 'react'
import { observer } from 'mobx-react-lite'

// import Typography from '@mui/material/Typography'
// import Divider from '@mui/material/Divider'

import SimpleMarkdown from './SimpleMarkdown'

import { useStore } from 'data/store/store'
import { useRouteStore } from 'data/store/route/RouteStore'

const StaticMarkdown = observer(props => {
  const store = useStore()
  const route = useRouteStore()

  const content = store.search.getStaticContent(props.contentId)

  return (
    <div>
      <SimpleMarkdown>{content.body}</SimpleMarkdown>
    </div>
  )
})

export default StaticMarkdown
