import React from 'react'

import { withStyles } from '@mui/styles'

import { Box, Button, Collapse, Fade, Typography } from '@mui/material'

import { shortLabel } from 'ui/utils/location-utils'
import { useConfig } from 'config/config'

const styles = theme => ({})

const GeolocationError = (props = {}) => {
  const { error, defaultLocationButton = false, onLocationChange = () => undefined } = props
  const { message, suggestDefaultLocation } = error || {}

  const { tenantConfig: tc } = useConfig()
  const cfg = tc?.search?.location || {}

  const defaultLocation = cfg.defaultLocation
  const defaultLocationLabel = shortLabel(defaultLocation)

  const showError = !!error
  // const showSuggestion = suggestDefaultLocation && defaultLocation
  const showSuggestion = !!defaultLocation

  const handleDefaultLocationClick = () => {
    onLocationChange({ type: 'defaultLocationClick', code: 'DefaultLocation' }, defaultLocation)
  }

  const suggest = showSuggestion && (
    <>
      <Typography variant="body2" gutterBottom>
        Search for a supported location, or select the default location below.
      </Typography>
      {defaultLocationButton && (
        <Button variant="outlined" size="small" sx={{ mt: 1 }} onClick={handleDefaultLocationClick}>
          {defaultLocationLabel}
        </Button>
      )}
    </>
  )

  return (
    <Collapse in={showError}>
      <Fade in={showError}>
        <Box sx={{ pl: 1, mt: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box component="i" className="far fa-exclamation-triangle" sx={{ mt: '1px', mr: 1 }} />
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 'medium' }}>
              {message}
            </Typography>
          </Box>
          {suggest}
        </Box>
      </Fade>
    </Collapse>
  )
}

export default withStyles(styles, { name: 'AMSGeolocationError' })(GeolocationError)
