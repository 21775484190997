import React from 'react'

import { observer } from 'mobx-react-lite'

import { styled } from '@mui/material/styles'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
import { usePlatform } from 'ui/hooks/usePlatform'
import { useLoadGoogleMaps } from 'ui/maps/useLoadGoogleMaps'

//import ResultsMapLeaflet from './ResultsMapLeaflet'
const ResultsMapLeaflet = React.lazy(() =>
  import('./ResultsMapLeaflet' /* webpackChunkName: "results-map-leaflet" */)
)

const Status = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.86rem;
`

const ResultsMap = observer(({ searchId, containerRef }) => {
  const store = useStore()
  const search = store.search.searches.get(searchId)

  const { tenantConfig: tc } = useConfig()
  const mapConfig = tc.ui?.search?.map || {}

  const platform = usePlatform()
  const { isLoaded, error } = useLoadGoogleMaps()

  React.useEffect(() => {
    if (platform.isBot) console.log('Bot detected, disabling googlemaps')
  }, [platform.isBot])

  const handleSelect = (source, result) => {
    // setTimeout(() => store.search.setSelected(source, result), 100)
  }

  if (platform.isBot) return null

  const Loading = () => <Status>Loading Google Maps...</Status>

  if (!isLoaded && !error) return <Loading />

  if (error)
    return (
      <Status>
        <div>Unable to load Google Maps</div>
        <div>Please try refreshing the page.</div>
      </Status>
    )

  const mapProps = {
    containerRef,
    searchId,
    search,
    config: tc.ui?.search?.map || {},
    results: search.mapResults,
    ...search.defaultMapParams,
    selected: search.control.selectedResult,
    onSelect: handleSelect,
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <ResultsMapLeaflet {...mapProps} />
    </React.Suspense>
  )
})

export default ResultsMap
