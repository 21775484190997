import { useConfig } from 'config/config'
import get from 'lodash/get'
import { addDays } from 'date-fns'

const { tenantConfig } = useConfig()

const ageMap = tenantConfig.survey.questionsMap['age'].queryMap
const defaultAge = { AgeMin: 0, AgeMax: 999 }

const lookupAges = age => {
  if (!age || !(age in ageMap)) return defaultAge

  const ages = ageMap[age]
  return { AgeMin: ages.min, AgeMax: ages.max }
}

const convertGender = gender => {
  const genderMap = {
    'Male': 'MaleOnly',
    'Female': 'FemaleOnly',
  }
  return get(genderMap, gender, null)
}

const calculateDateRangeParams = dateRange => {
  if (dateRange.delta < 0) {
    const from = dateRange.start || new Date()
    const to = dateRange.end ? addDays(dateRange.end, 1) : addDays(from, 90)

    return {
      SearchFrom: from.toISOString(),
      SearchTo: to.toISOString(),
    }
  }

  console.error(`date delta not implemented ${dateRange.delta}`)
}

export const buildDirectorySearch = ({
  params,
  categories,
  tags,
  pageSize,
}) => {
  const ages = lookupAges(params.age)
  const gender = convertGender(params.gender)

  const searchQuery = {
    // ServiceChanges: true,
    SearchTerm: params.query,
    SortField: "Distance",
    SortDirection: "ASC",
    Categories: categories,
    CategoriesRequired: true,
    // LocationRegions: [],
    Gender: gender,
    Tags: tags,
    PageSize: pageSize,
    PageNo: 1,
    Latitude: params.location.lat,
    Longitude: params.location.lng,
    Radius: params.radius,
    ...ages,
  }
  return searchQuery
}

export const buildDirectorySearchWAPHA = ({
  params,
  commissionedServices,
  pageSize,
}) => {
  const csids = commissionedServices.length > 0 ? commissionedServices : [-1]

  const searchQuery = {
    SearchTerm: params.query,
    SortField: "Name",
    SortDirection: "ASC",
    LocationRegions: [params.location.id],
    IsCommissioned: true,
    CommissionedServices: csids,
    PageSize: pageSize,
    PageNo: 1,
  }

  return searchQuery
}

export const buildDirectorySearchHealth = ({
  params,
  categories,
  // tags,
  pageSize,
}) => {
  const ages = lookupAges(params.age)
  // const gender = convertGender(params.gender)

  const searchQuery = {
    What: params.query,
    SortField: "Distance",
    SortDirection: "ASC",
    ServiceCategories: categories,
    // CategoriesRequired: true,
    // LocationRegions: [],
    // Gender: gender,
    // Tags: tags,
    PageSize: pageSize,
    PageNo: 1,
    // Latitude: params.location.lat,
    // Longitude: params.location.lng,
    Location: params.location.id,
    Radius: params.radius,
    ...ages,
  }

  // console.log(searchQuery)

  return searchQuery
}



export const buildDiarySearch = ({
  params,
  categories,
  pageSize,
}) => {

  const dates = calculateDateRangeParams(params.dateRange)

  const searchQuery = {
    PageSize: pageSize,
    PageNumber: 1,
    // Latitude: params.location.lat,
    // Longitude: params.location.lng,
    LocationID: params.location.id,
    RadiusKM: params.radius,
    ServiceCategories: categories,
    // UniqueEvents: true,
    WhatText: params.query,
    WhenIndex: 'Custom',
    // SearchFrom: (new Date()).toISOString(),
    // SearchTo: '2021-01-31T03:51:07.239Z',
    // IncludeDeletedAndUnpublished: false,
    // AffiliateSiteID: 47,
    ...dates,
  }

  return searchQuery
}

// favourites

export const toFavourite = (result, search) => ({
  ...result,
  id: `${result.id}_fav`,
  search,
})

export const fromFavourite = favourite => ({
  ...favourite,
  id: favourite.id.replace(/_fav$/, ''),
})

