import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { color } from '@mui/system'
import backgroundImage from './assets/images/myservicesbg.png'

const colors = {
  primary: '#267dc1',
  secondary: '#FFF7ED',
  text: {
    primary: grey[700],
    secondary: grey[600],
    disabled: grey[400],
    footer: '#3e2717',
  },
}

const dimensions = {
  parameters: 370,
  results: 450,
  outlinedInput: {
    root: {
      padding: 0.5,
    },
    input: {
      padding: 0.75,
    },
  },
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    ph: 740,
    md: 900,
    lg: 1200,
    tb: 1350,
    xl: 1536,
  },
}

const theme = createTheme({
  breakpoints,
})

export const themes = {
  base: {
    breakpoints,
    palette: {
      primary: {
        main: colors.primary,
        contrastText: 'white',
      },
      secondary: {
        main: colors.secondary,
        contrastText: 'white',
      },
      default: {
        main: grey[200],
        light: grey[100],
        dark: grey[400],
      },
      grey: {
        main: grey[700],
        light: grey[400],
        dark: grey[600],
      },
      homeButton: {
        main: grey[600],
        light: grey[400],
        dark: grey[700],
      },
      background: {
        default: 'white',
        paper: 'white',
        image: `url(${backgroundImage})`,
      },
      text: {
        // primary: colors.text.primary,
        ...colors.text,
      },
    },

    typography: {
      fontFamily: [
        'Nunito',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(', '),
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: theme.spacing(dimensions.outlinedInput.root.padding),
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: theme.spacing(1),
            },
          },
          input: {
            padding: theme.spacing(dimensions.outlinedInput.input.padding),
          },
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 46,
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            // fontSize: '0.875rem',
            fontSize: '1rem',
            padding: theme.spacing(0, 0),
            minHeight: 46,
            [theme.breakpoints.up('sm')]: {
              minWidth: 140,
            },
          },
        },
      },

      // AppBar

      AMSAppBar: {
        props: {
          elevation: 0,
          position: 'static',
        },
        styleOverrides: {
          root: {
            backgroundColor: colors.secondary,
            // backgroundColor: 'white',
            boxShadow: '0 0px 4px 0px rgba(0, 0, 0, 0.4)',
            color: '#267dc1',
            // color: colors.text.primary,
            flexShrink: 1,
          },
          row: {
            [theme.breakpoints.down('md')]: {
              minHeight: 48,
            },

            [theme.breakpoints.down('sm')]: {
              // padding: theme.spacing(0, 1),
            },
          },
        },
      },

      AMSAppBarGroup: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0.5),
            },
          },
          left: {
            [theme.breakpoints.down('sm')]: {
              flex: '1 0',
            },
          },
          fill: {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
          right: {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
        },
      },

      AMSMenuButton: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.8rem',
            },
          },
        },
      },

      AMSAppBarTitle: {
        styleOverrides: {
          logo: {
            height: 90,
            [theme.breakpoints.down('sm')]: {},
          },
          title: {
            color: 'white',
            // color: colors.text.primary,
            fontWeight: 600,
          },
        },
      },

      // home buttons

      AMSHomeButtons: {
        defaultProps: {
          maxWidth: 'md',
          // py:
        },
      },

      AMSHomeButton: {
        defaultProps: {
          homeButtonBgColor: 'white',
          // color: 'primary',
          // variant: 'contained',
          variant: 'outlined',
          sx: { py: { xs: 2, md: 6 }, borderRadius: 4 },
        },
        styleOverrides: {
          root: {
            // backgroundColor: '#f00',
            color: grey[700],
          },
          iconContainer: {
            borderRadius: '50%',
            width: 100,
            height: 100,
            backgroundColor: colors.primary,
            color: 'white',
            [theme.breakpoints.down('md')]: {
              width: 45,
              height: 45,
              fontSize: '1.125rem',
            },
          },
          label: {
            fontSize: '1',
            [theme.breakpoints.down('md')]: {
              fontSize: '1rem',
            },
          },
        },
      },

      // desktop search

      AMSDesktopSearch: {
        styleOverrides: {
          parameterDrawer: {
            minWidth: dimensions.parameters,
            maxWidth: dimensions.parameters,
          },
          parameters: {
            width: dimensions.parameters,
          },
          results: {
            width: dimensions.results,
            // backgroundColor: '#f0f',
            // width: 300,
            [theme.breakpoints.down('md')]: {
              // width: 300,
              // width: 380,
            },

            [theme.breakpoints.between('ph', 'md')]: {
              width: 'calc(100vw / 2)',
            },
          },
        },
      },
    },

    AMSDesktopSearchToolbar: {
      toolbarButton: {
        fontSize: '1rem',
      },
    },
  },

  variants: {
    footer: {
      palette: {
        text: {
          primary: 'white',
          secondary: 'grey',
          // secondary: grey[900],
          disabled: grey[400],
        },
        divider: 'rgba(0, 0, 0, 0.2)',
      },
      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              color: '#FFA500',
              fontWeight: 600,
            },
          },
        },

        AMSFooterSection: {
          styleOverrides: {
            root: {
              fontSize: '0.86rem',
              // backgroundColor: colors.primary,
              backgroundColor: '#4c4d4d',
            },
          },
        },

        AMSCieBlock: {
          styleOverrides: {
            body1: {
              // fontSize: '0.86rem',
            },
          },
        },

        // AMSSponsors: {
        //   styleOverrides: {
        //     label: {
        //       padding: theme.spacing(0),
        //       marginBottom: theme.spacing(2),
        //     },
        //     imageList: {
        //       justifyContent: 'center',
        //       gap: 30,
        //     },
        //     imageContainer: {
        //       maxHeight: 60,
        //       [theme.breakpoints.down('lg')]: {
        //         maxHeight: 50,
        //       },
        //       [theme.breakpoints.down('sm')]: {
        //         maxHeight: 40,
        //       },
        //     },
        //   },
        // },
      },
    },
  },
}
