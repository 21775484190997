import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import clsx from 'clsx'

import { styled, css } from '@mui/material/styles'
import { Box, Button, IconButton, Fab, Drawer } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { neatJson } from 'ui/vendor/neatjson'

import { Scrollbars } from 'react-custom-scrollbars'

export const tokens = {
  backgroundColor: '#334',
  color: '#eee',
  menuColor: '#999',
  menuHover: '#fff',
  menuSelected: '#eee',
  menuSelectedFontWeight: '700',
  font: '"JetBrains Mono", monospace',
  fontSize: '12px',
}

export const MonoBox = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  width: 100vw;
  z-index: 3000;
  background-color: ${tokens.backgroundColor};
  font-family: ${tokens.font};
  font-size: ${tokens.fontSize};
  color: ${tokens.color};
`

export const Mono = styled(Box)`
  font-family: ${tokens.font};
  font-size: ${tokens.fontSize};
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const MFA = ({ icon, className: cn }) => <i className={clsx(cn, icon, 'fa-fw')} />
export const MonoFa = styled(MFA)`
  padding: 3px;
`

const forceWrap = (value, wrap = 120) => {
  return value
    .split('\n')
    .map(l => {
      if (l.length < wrap) return l

      let ol = []
      let il = l

      const lf = l.indexOf(':') + 2
      const wr = wrap - lf

      ol.push(il.slice(0, wrap))
      il = il.slice(wrap)

      do {
        ol.push(' '.repeat(lf) + il.slice(lf, wrap))
        il = il.slice(wr, il.length)
      } while (il.length > 0)

      return ol.join('\n')
    })
    .join('\n')
}

export const Json = ({ value }) => {
  const [rendered, setRendered] = useState()

  useEffect(() => {
    setRendered(() => forceWrap(neatJson(value)))
  }, [value])

  return <Box>{rendered}</Box>
}

const ValueItem = styled(Box)``

export const ValuesList = ({ value }) => {
  const items = _.map(value, (v, k) => {
    const jv = neatJson(v)

    return (
      <Box key={k} sx={{}}>
        {k}: {jv}
      </Box>
    )
  })

  return <Box>{items}</Box>
}

export const ScrollPanel = ({ children }) => {
  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <Scrollbars style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
        {children}
      </Scrollbars>
    </Box>
  )
}
