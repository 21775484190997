import React from 'react'
import { configure, toJS } from 'mobx'
import { enableLogging } from 'mobx-logger'

import { RootStore } from './models/RootStore'
import { initStoreConfigs } from './utils/user-data'
import { getPlatform } from 'ui/hooks/usePlatform'

const platform = getPlatform()

// proxies not compatible with ie11

configure({
  useProxies: !!platform.ieVersion,

  // enforceActions: 'always',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  // disableErrorBoundaries: true,
})

enableLogging({
  predicate: () => true,
})

window.toJS = toJS

export const store = (window.store = (() => {
  const configs = initStoreConfigs()
  // console.log({ configs })

  const store = (({ defaultConfig, userConfig }) => {
    try {
      return RootStore.create(userConfig || defaultConfig)
    } catch (err) {
      console.error('Error loading store, reverting to default config', err)
      return RootStore.create(defaultConfig)
    }
  })(configs)

  // s.postInit()
  return store
})())

const storeContext = React.createContext(store)
export const useStore = () => React.useContext(storeContext)
