/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
// import React from 'react'

const appConfig = {
  version: 3,
  apiKey: process.env.REACT_APP_CIE_API_KEY,
  apiURL: window.REACT_APP_CIE_API_URL,
  directoryURL: window.REACT_APP_DIRECTORY_URL,
  diaryURL: window.REACT_APP_DIARY_URL,
  cdnURL: process.env.REACT_APP_CDN_URL,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  debugHelpers: process.env.REACT_APP_DEBUG_HELPERS === 'true',
  tenant: process.env.TENANT,
}

const tenantConfig = require(`tenants/${process.env.TENANT}/config.js`).default

export const config = {
  appConfig,
  tenantConfig,
}

window.config = config

// const configContext = React.createContext(config)
// const useConfig = () => React.useContext(configContext)
export const useConfig = () => config
