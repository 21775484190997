import { autorun, toJS } from 'mobx'
import { types, flow } from 'mobx-state-tree'
import { analytics } from 'data/analytics/analytics'
import { useConfig } from 'config/config'

import { Tenant } from './Tenant'
import { SearchControl } from './SearchControl'
import { Survey } from './Survey'

const { appConfig, tenantConfig: tc } = useConfig()

export const RootStore = types
  .model({
    version: appConfig.version || 0,
    tenant: types.optional(Tenant, {}),
    // params: types.optional(SearchParams, {}),
    search: types.optional(SearchControl, {}),
    survey: types.optional(Survey, {}),
  })

  .views(self => ({
    get helpers() {
      return {
        tenant: { store: self.tenant },
        search: { store: self.search },
        survey: { store: self.survey },
      }
    },

    get saveData() {
      const sd = {
        version: self.version,
        params: {
          ...self.search.params,
          query: '',
        },
        ui: self.search.ui,
        survey: self.survey,
        favourites: self.search.favouritesSearch?.results,
      }

      return sd
    },
  }))

  .actions(self => ({
    afterCreate() {
      self.postInit()
    },

    postInit() {
      self.tenant.loadVolatile()
      self.search.loadDefaultFilters()
      self.search.params.initLocation()
      self.search.loadContentRegions(tc.contentRegions || [])

      // trigger search

      autorun(() => {
        const params = toJS(self.search.params)
        const showAllCategories = toJS(self.search.ui.showAllCategories)

        // args not used, but passed as observed deps
        self.search.initSearch(params, showAllCategories)
      })

      // save user data

      autorun(
        () => {
          const psjson = JSON.stringify(toJS(self.saveData), null, 2)
          const key = `ams-userstore-${appConfig.tenant || 'default'}`
          window.localStorage.setItem(key, psjson)
        },
        {
          delay: 1000,
        }
      )
    },

    resetAll() {
      analytics.track('resetAll', {
        categories: 'Navigation',
        label: 'Reset All',
      })
      analytics.reset()

      self.survey = {}
      self.search = {
        searches: tc.search.searches,
        params: {
          ...tc?.search?.defaultParams,
        },
        ui: {},
      }

      self.search.params.initLocation()
    },

    resetSurvey() {
      self.survey = {}
    },
  }))
