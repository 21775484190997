import React from 'react'

import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'

import { BaseLink } from 'ui/nav/Link'
import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
import { useQuery } from 'react-query'

import { getPromotions } from 'data/api/cie-promotions'

const Promotions = observer(() => {
  const { appConfig: ac } = useConfig()
  const { tenant } = ac

  const store = useStore()
  const { councilId } = store.search.params.location || {}
  const params = councilId ? { tenant, councilId } : null

  const { data } = useQuery(['cie-promotions', params], async () => getPromotions(params), {
    enabled: !!params,
    refetchOnWindowFocus: false,
    select: ({ data }) => data,
  })

  if (!data) return null

  return (
    <Box sx={{ p: 2 }}>
      {data.map(({ captionText, url }, i) => (
        <Box key={i}>
          <BaseLink href={url}>
            <Box component="i" className="fas fa-file-pdf" sx={{ mr: 1 }} />
            {captionText}
          </BaseLink>
        </Box>
      ))}
    </Box>
  )
})
export default Promotions
