import _ from 'lodash'

import { createMachine, assign, send, actions } from 'xstate'
const { raise, choose, log } = actions

const getBrowserUrl = ({ query, url }) => (query ? [url, query].join('?') : url)

const updateBrowserUrl = urlInfo => ({ ...urlInfo, browserUrl: getBrowserUrl(urlInfo) })

const clearQueryParams = ({ query, queryParams, ...urlInfo }) => updateBrowserUrl(urlInfo)

const setRedirectAction = redirectAction => assign(c => ({ redirectAction }))

const defaultMachineOptions = {
  actions: {
    logUrls: c => {
      const urls = _.fromPairs(['router', 'store', 'current'].map(k => [k, c[k]?.browserUrl]))
      console.table(urls)
    },

    setRouterInfo: assign((c, { urlInfo }) => urlInfo && { router: updateBrowserUrl(urlInfo) }),
    setStoreInfo: assign((c, { urlInfo }) => urlInfo && { store: updateBrowserUrl(urlInfo) }),
    //updateBrowserUrl:

    initCurrent: assign(({ defaultUrl, router, store, current }) => {
      if (current.browserUrl) return
      if (router.browserUrl === store.browserUrl) return { current: store }
      if (router.browserUrl === defaultUrl) return { current: router }
      return { current: store }
    }),

    setRouterCurrent: assign(c => ({ current: c.router })),
    setStoreCurrent: assign(c => ({ current: c.store })),

    clearRedirect: assign(c => ({ redirect: undefined })),
    setCurrentFromRedirect: assign(c => ({ current: c.redirect })),
    setRedirectFromStore: assign(c => ({ redirect: c.store })),
    removeQueryAndRedirect: assign(c => ({ redirect: clearQueryParams(c.router) })),
  },
  guards: {
    urlsInvalid: c => !c.router.browserUrl || !c.store.browserUrl,

    urlsValid: c => !!c.router.browserUrl && !!c.store.browserUrl,

    currentValid: c => !!c.current.browserUrl,

    resetLocation: c => !!c.router.queryParams?.rloc,

    unknownQueryParams: c => c.router.queryParams && !c.router.queryParams?.rloc,

    redirectFromDefault: c => c.router.url === c.defaultUrl && c.router.url !== c.store.url,

    routerChanged: c =>
      c.current.url && c.store.url === c.current.url && c.router.url !== c.current.url,

    storeChanged: c =>
      c.current.url && c.store.url !== c.current.url && c.router.url === c.current.url,

    routerMatchesStore: c => c.router.url === c.store.url,

    redirectMatchesRouter: c => c.redirect && c.redirect.browserUrl === c.router.browserUrl,
  },
}

export const searchRouteMachine = createMachine(
  {
    id: 'searchRoute',
    initial: 'init',
    // initial: 'init',
    context: {
      defaultUrl: '/',
      // defaultUrl: '/search',
      current: {
        url: undefined,
      },
      router: {
        url: undefined,
      },
      store: {
        url: undefined,
      },
      redirect: undefined,
      redirectAction: null,
    },
    on: {
      SET_ROUTER_INFO: {
        actions: ['setRouterInfo', send('ROUTER_INFO')],
      },
      SET_STORE_INFO: {
        actions: ['setStoreInfo', send('STORE_INFO')],
      },
    },
    states: {
      init: {
        exit: ['initCurrent', send('URL_CHECK')],
        on: {
          ROUTER_INFO: {
            target: 'idle',
            cond: 'urlsValid',
          },
          STORE_INFO: {
            target: 'idle',
            cond: 'urlsValid',
          },
          IDLE: { target: 'idle', cond: 'currentValid' },
        },
      },
      idle: {
        // entry: 'logUrls',
        on: {
          ROUTER_INFO: {
            actions: send('URL_CHECK'),
          },
          STORE_INFO: {
            actions: send('URL_CHECK'),
          },
          URL_CHECK: { target: 'compare' },
        },
      },
      compare: {
        entry: send('COMPARE_URLS'),
        on: {
          COMPARE_URLS: [
            // {
            //   target: 'resetLocation',
            //   cond: 'resetLocation',
            // },
            {
              target: 'redirect',
              cond: 'resetLocation',
              actions: [
                // () => console.log('resetLocation'),
                // 'resetLocation',
                'removeQueryAndRedirect',
                setRedirectAction('resetLocation'),
              ],
            },
            {
              target: 'redirect',
              cond: 'unknownQueryParams',
              actions: [
                // () => console.log('unknownQueryParams'),
                'removeQueryAndRedirect',
              ],
            },
            {
              target: 'redirect',
              cond: 'redirectFromDefault',
              actions: [
                // () => console.log('redirectFromDefault'),
                'setRedirectFromStore',
                setRedirectAction('currentFromRedirect'),
              ],
            },
            {
              target: 'redirect',
              cond: 'storeChanged',
              actions: [
                // () => console.log('storeChanged'),
                'setRedirectFromStore',
                setRedirectAction('currentFromRedirect'),
              ],
            },
            {
              target: 'parseUrl',
              cond: 'routerChanged',
            },
            { target: 'idle' },
          ],
        },
      },
      redirect: {
        id: 'redirect',
        initial: 'redirect',
        entry: [
          // () => console.log('redirect entry'),
          // ({ redirectAction }) => console.log('redirectAction', redirectAction),
        ],
        exit: [
          setRedirectAction(null),
          // assign(c => ({ setRedirectStates: null })),
          // c => console.log('redirect exit', c),
        ],
        states: {
          redirect: {
            always: [{ target: 'wait', actions: 'redirect' }],
          },
          wait: {
            entry: send('CHECK_REDIRECT'),
            // after: {
            //   2000: { target: 'timeout' },
            // },
            on: {
              ROUTER_INFO: { actions: send('CHECK_REDIRECT') },
              CHECK_REDIRECT: [{ target: 'finish', cond: 'redirectMatchesRouter' }],
            },
          },
          finish: {
            entry: choose([
              //resetLocation
              {
                cond: c => c.redirectAction === 'currentFromRedirect',
                actions: ['setCurrentFromRedirect', 'clearRedirect'],
              },
              {
                cond: c => c.redirectAction === 'resetLocation',
                actions: ['clearRedirect', 'resetLocation'],
              },
              {
                actions: 'clearRedirect',
              },
            ]),
            always: [{ target: 'complete' }],
          },
          timeout: {
            always: [{ target: 'complete' }],
          },
          complete: {
            type: 'final',
            entry: 'clearRedirect',
          },
        },
        onDone: 'compare',
      },
      parseUrl: {
        id: 'parseUrl',
        initial: 'pending',
        //entry: send('')
        states: {
          pending: {
            id: 'parseUrl',
            entry: 'cacheQueryResultsFromStore',
            invoke: {
              src: 'queryRouteParams',
              onDone: {
                target: 'success',
                actions: 'writeQueryResultToStore',
              },
              onError: {
                target: 'restore',
                actions: (c, { data }) => console.warn('parseUrl error', { data }),
              },
            },
          },
          success: {
            // entry: [() => console.log('Success!')],
            after: {
              100: { actions: send('STORE_INFO') },
            },
            on: {
              STORE_INFO: [
                {
                  target: 'complete',
                  actions: ['setStoreInfo', 'setRouterCurrent'],
                },
              ],
            },
          },
          restore: {
            entry: send('RESTORE'),
            on: {
              RESTORE: {
                target: 'complete',
                actions: 'setRouterCurrent',
              },
            },
          },
          complete: {
            type: 'final',
          },
        },
        onDone: 'compare',
      },
    },
  },
  defaultMachineOptions
)

// console.log('MACHINE', searchRouteMachine)
