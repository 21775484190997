import { createMachine, assign, send, actions } from 'xstate'

// const { raise } = actions

export const routeMachine = createMachine(
  {
    id: 'searchRoute',
    initial: 'init',
    context: {
      defaultUrl: '/',
      router: {
        url: undefined,
      },
      redirect: undefined,
    },
    states: {
      init: {
        on: {
          SET_ROUTER_INFO: { actions: ['setRouterInfo', send('EVAL_CURRENT')] },
          EVAL_CURRENT: {
            target: 'idle',
            cond: 'urlsValid',
          },
        },
      },
      idle: {},
      // idle: {
      //   entry: send('COMPARE_URLS'),
      //   on: {
      //     SET_ROUTER_INFO: { actions: ['setRouterInfo', send('COMPARE_URLS')] },
      //     SET_STORE_INFO: { actions: ['setStoreInfo', send('COMPARE_URLS')] },
      //     COMPARE_URLS: [
      //       {
      //         target: 'redirect',
      //         cond: 'redirectFromDefault',
      //         actions: 'setRedirectFromStore',
      //       },
      //       {
      //         target: 'redirect',
      //         cond: 'storeChanged',
      //         actions: 'setRedirectFromStore',
      //       },
      //       {
      //         target: 'parseUrl',
      //         cond: 'routerChanged',
      //       },
      //     ],
      //   },
      // },
      redirect: {
        initial: 'init',
        states: {
          init: {
            entry: send('REDIRECT'),
            on: {
              REDIRECT: [
                { target: 'complete', cond: 'redirectMatchesRouter' },
                { target: 'redirect' },
              ],
            },
          },
          redirect: {
            entry: 'redirect',
            on: {
              SET_ROUTER_INFO: {
                target: 'complete',
                actions: [
                  'setRouterInfo',
                  'setCurrentFromRedirect',
                  'clearRedirect',
                  // assign(c => ({ redirect: undefined, current: c.redirect })),
                ],
              },
            },
          },
          complete: {
            type: 'final',
            entry: 'clearRedirect',
          },
        },
        onDone: 'idle',
      },
      parseUrl: {
        initial: 'pending',
        //entry: send('')
        states: {
          pending: {
            id: 'parseUrl',
            entry: 'cacheQueryResultsFromStore',
            invoke: {
              src: 'queryRouteParams',
              onDone: {
                target: 'success',
                actions: [
                  // (c, { data }) => console.log('parseUrl result', { data }),
                  'writeQueryResultToStore',
                ],
              },
              onError: {
                target: 'restore',
                actions: (c, { data }) => console.warn('parseUrl error', { data }),
              },
            },
          },
          success: {
            entry: [() => console.log('Success!')],
            on: {
              SET_STORE_INFO: [
                {
                  target: 'complete',
                  actions: ['setStoreInfo', 'setStoreCurrent'],
                },
              ],
            },
          },
          restore: {
            entry: send('RESTORE'),
            on: {
              RESTORE: {
                target: 'complete',
                actions: 'setRouterCurrent',
              },
            },
          },
          complete: {
            type: 'final',
          },
        },
        onDone: 'idle',
      },
    },
  },
  {
    actions: {
      setRouterInfo: assign((c, { urlInfo }) => urlInfo && { router: urlInfo }),
      setStoreInfo: assign((c, { urlInfo }) => urlInfo && { store: urlInfo }),

      initCurrent: assign(c => {
        if (c.current.url) return
        if (c.router.url === c.store.url) return { current: c.store }
        if (c.router.url === c.defaultUrl) return { current: c.router }
        return { current: c.store }
      }),

      setRouterCurrent: assign(c => ({ current: c.router })),
      setStoreCurrent: assign(c => ({ current: c.store })),

      clearRedirect: assign(c => ({ redirect: undefined })),
      setCurrentFromRedirect: assign(c => ({ current: c.redirect })),
      setRedirectFromStore: assign(c => ({ redirect: c.store })),
    },
    guards: {
      urlsInvalid: c => !c.router.url,

      urlsValid: c => !!c.router.url,

      // redirectFromDefault: c => c.router.url === c.defaultUrl && c.router.url !== c.store.url,

      // routerChanged: c =>
      //   c.current.url && c.store.url === c.current.url && c.router.url !== c.current.url,

      // storeChanged: c =>
      //   c.current.url && c.store.url !== c.current.url && c.router.url === c.current.url,

      // routerMatchesStore: c => c.router.url === c.store.url,

      // redirectMatchesRouter: c => c.redirect && c.redirect.url === c.router.url,
    },
  }
)
