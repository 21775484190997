import React from 'react'

import { useTheme } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import { Box, Typography, Link } from '@mui/material'
// import mentalHealthCommissionLogo from '../../tenants/myservices/assets/images/mental-health-commission-logo.svg'

import { useConfig } from 'config/config'

const styles = theme => ({
  root: {
    padding: theme.spacing(4, 2),
  },
  logo: {
    maxWidth: 100,
    opacity: 0.76,
    marginBottom: theme.spacing(1),
    width: 100,
  },
  body1: {
    // fontSize: 'inherit',
    fontSize: '0.86rem',
    // fontSize: 14,
  },
  mentalHealthCommissionLogo: {
    color: 'white',
    margin: '10px 65px 15px 0px',
  },
})

const CieBlock = ({ classes }) => {
  const { tenantConfig: tc } = useConfig()

  return (
    <div className={classes.cie}>
      {tc.assets.mentalHealthCommissionLogo && (
        <Box
          as="img"
          className={classes.mentalHealthCommissionLogo}
          src={tc.assets.mentalHealthCommissionLogo}
        />
      )}
      <Box as="img" className={classes.logo} src={tc.assets.cie.logo} />
      <Typography className={classes.body1} variant="body1">
        Powered by the{' '}
        <Link
          href="https://www.communityinformationexchange.com.au/"
          target="_blank"
          underline="none"
          sx={{ whiteSpace: 'nowrap' }}
        >
          Community Information Exchange
        </Link>
        <br />© {new Date().getFullYear()} My Community Directory
        <br />
        All rights reserved. ABN: 18 136 511 512
      </Typography>
    </div>
  )
}

export default withStyles(styles, { name: 'AMSCieBlock' })(CieBlock)
