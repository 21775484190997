import React, { useEffect } from 'react'

import _ from 'lodash'
import { toJS, computed } from 'mobx'
import { observer } from 'mobx-react-lite'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import { neatJson } from 'ui/vendor/neatjson'

import { useDebugStore } from 'data/store/debug/DebugStore'
import { Json } from './Mono'

const Dump = styled('pre')`
  font-family: 'JetBrains Mono', monospace;
`

const RouteMachine = observer(() => {
  const store = useDebugStore()

  const { urlInfo } = store.routeMachine

  const urlJson = neatJson(urlInfo)

  // const { _helper } = store
  // if (!_helper) return null

  // const json = _.get(store, _helper.pathStr)
  // if (!json) return null

  // const rendered = neatJson(json)

  return (
    <Box>
      <Dump>{urlJson}</Dump>
    </Box>
  )
})

export default RouteMachine
