import { useEffect, useRef } from 'react'

const useGoogleAnalyticsTagManager = containerID => {
  const scriptRef = useRef(null)
  const noScriptRef = useRef(null)

  useEffect(() => {
    if (!scriptRef.current) {
      const script = document.createElement('script')

      script.textContent = `(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', '${containerID}')`
      scriptRef.current = script
      document.head.appendChild(script)
    }

    if (!noScriptRef.current) {
      const iframe = document.createElement('iframe')
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${containerID}`
      iframe.height = '0'
      iframe.width = '0'
      iframe.style.display = 'none'
      iframe.style.visibility = 'hidden'

      const noscript = document.createElement('noscript')
      noscript.appendChild(iframe)
      noScriptRef.current = noscript
      document.body.appendChild(noscript)
    }

    return () => {
      document.head.removeChild(scriptRef.current)
      scriptRef.current = null

      document.body.removeChild(noScriptRef.current)
      noScriptRef.current = null
    }
  }, [containerID])
}

export const removeDuplicateScript = () => {
  const scripts = document.getElementsByTagName('script')
  // Create an array to store the unique script src values
  let srcValues = []

  // Loop through the script elements and add their src values to the array
  for (var i = 0; i < scripts.length; i++) {
    srcValues.push(scripts[i].src)
  }

  // Remove duplicates from the array
  srcValues = srcValues.filter(function (item, pos) {
    return srcValues.indexOf(item) === pos
  })
  // Loop through the script elements again and remove any that have a src value that is not in the unique array
  for (let i = 0; i < scripts.length; i++) {
    if (srcValues.indexOf(scripts[i].src) === -1) {
      scripts[i].parentNode.removeChild(scripts[i])
    }
  }
}

export default useGoogleAnalyticsTagManager
