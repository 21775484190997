import React from 'react'

import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@mui/styles'
import { Box, Container, Typography, Divider, Button } from '@mui/material'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 18,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  viewInfoButton: {
    marginTop: theme.spacing(3),
  },
}))

const InfoOnly = observer(() => {
  const classes = useStyles()

  const store = useStore()
  const category = store.search.selectedInformationCategory

  const { tenantConfig: tc } = useConfig()
  const infoTab = tc.ui.search.tabs.findIndex(x => x.id === 'information')

  const handleViewInfo = () => {
    if (infoTab < 0) return
    store.search.ui.setSelectedTab(infoTab)
  }

  return (
    <Container className={classes.root} maxWidth="md">
      <Box className={classes.box} py={4}>
        <Typography className={classes.title} variant="h4" gutterBottom>
          {category.title}
        </Typography>
        <Divider />
        <br />

        <div className={classes.content}>
          <Typography variant="body2" color="textSecondary" align="center" className={classes.text}>
            This is an information only category.
          </Typography>

          {infoTab >= 0 && (
            <div className={classes.viewInfoButton}>
              <Button
                variant="outlined"
                color="primary"
                mt={3}
                disableElevation
                onClick={handleViewInfo}
              >
                <i className={clsx('fas fa-info-circle', classes.icon)} />
                View Information
              </Button>
            </div>
          )}
        </div>
      </Box>
    </Container>
  )
})

export default InfoOnly
