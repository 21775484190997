/* eslint-disable @typescript-eslint/no-explicit-any */

import { types, getRoot, Instance } from 'mobx-state-tree'

import _ from 'lodash'

import { formatDistance } from '../utils/store-utils'
import { asArray } from '../utils/misc-helpers'
import { useConfig } from '../../../config/config'

const { tenantConfig: tc } = useConfig()

const csMap = _.keyBy(tc.commissionedServiceLookup?.typeMap || [], 'category')

const badges = (tc.categories || [])
  .filter((c: any) => !!c.badge)
  .map((c: any) => ({
    // ...c.badge,
    tagId: c.badge.tagId,
    category: c.id,
    label: c.title,
    styles: {
      default: {
        backgroundColor: c.badge.backgroundColor,
      },
    },
  }))

export const Service = types
  .model({
    type: types.literal('service'),
    // id: types.refinement(types.identifier, identifier => identifier.indexOf('services_') === 0),
    id: '',
    search: '',
    category: '',
    operatingDays: '',
    name: '',
    organisationName: '',
    address: '',
    phone: '',
    description: '',
    organisationId: 0,
    outletId: 0,
    commissionedServiceId: types.maybeNull(types.number),
    url: '',
    logoUrl: '',
    healthEngineId: types.maybeNull(types.number),
    healthEngineStatus: types.maybeNull(types.string),
    healthEngineAppointment: types.maybeNull(types.string),
    lat: 0.0,
    lng: 0.0,
    distance: 0.0,
    serviceChanges: types.maybeNull(
      types.model({
        openingHours: '',
        serviceChangesOrClosures: '',
        extraServicesOrEvents: '',
        emergencyContact: '',
        eligibilityConditions: '',
      })
    ),
    serviceTags: types.array(types.number),
    networks: types.array(
      types.model({
        networkId: 0,
        membershipId: 0,
        membershipType: '',
      })
    ),
    TrustedProvider: false,
  })

  .views(self => ({
    get root(): any {
      return getRoot(self) as any
    },

    get geometry() {
      return {
        lat: self.lat,
        lng: self.lng,
      }
    },

    get addressWithheld() {
      return self.address === '--withheld--'
    },

    get distanceLabel() {
      return formatDistance(self.distance)
    },

    get isFavourite() {
      return this.root.search.isFavourite(self.id)
    },

    get commissionedServiceTypes() {
      const root = getRoot(self) as any
      const cats = root.tenant.categoriesForCommissionedService(self.commissionedServiceId)

      const sc = root.search.activeCategoriesSet

      return cats.map((c: any) => {
        const tm = csMap[c.id]
        return {
          label: c.title,
          icon: tm?.icon,
          category: c.id,
          tagId: tm?.tagId,
          active: sc.has(c.id),
          styles: {
            default: {
              backgroundColor: tm?.backgroundColor,
            },
          },
        }
      })
    },

    get badges() {
      const acs = this.root.search.activeCategoriesSet

      return badges
        .filter((b: any) => self.serviceTags.includes(b.tagId))
        .map((b: any) => ({ ...b, active: acs.has(b.category) }))
    },
  }))

export type IService = Instance<typeof Service>
