import _ from 'lodash'
import Qs from 'qs'

import {
  getStates,
  getCouncilsForState,
  getLocalitiesForCouncil,
  getLocalityByParams,
} from 'data/api/cie-api'

const checkState = ({ state }) => {
  if (state !== 'Queensland') return { isValid: false }
  return { isValid: true }
}

export const queries = {
  undefined: {
    queryKey: () => ['undefined'],

    lookup: async () => {
      // console.error('lookup is undefined')
      return new Promise(accept => {
        setTimeout(() => accept({}), 10)
      })
    },
  },

  states: {
    queryKey: () => ['states'],

    lookup: async () => {
      var res = await getStates()
      if (!res.states) throw new Error()

      return {
        isValid: true,
        title: 'States',
        items: res.states
          // TODO: FIX!!
          .filter(s => s.shortUrl === 'QLD')
          .map(s => ({
            pathType: 'browseState',
            name: s.name,
            // params: { state },
            source: {
              state: s,
            },
          })),
      }
    },
  },

  councils: {
    queryKey: ({ state }) => ['councils', { state }],

    lookup: async ({ params }) => {
      const { state } = params

      const cs = checkState(params)
      if (!cs.isValid) return cs

      var res = await getCouncilsForState({ state })
      if (!res.councils) return { isValid: false }

      return {
        isValid: true,
        title: `Councils in ${res.state.name}`,
        items: res.councils
          .map(c => {
            const [, stateUrl, councilUrl] = c.url.split('/')
            return { ...c, stateUrl, councilUrl }
          })
          .map(c => ({
            pathType: 'browseCouncil',
            name: c.displayNameCouncil,
            // params: {
            //   ...c.params,
            // },
            source: {
              council: c,
            },
          })),
      }
    },
  },

  localities: {
    queryKey: ({ state, council }) => ['localities', { state, council }],

    lookup: async ({ params }) => {
      const cs = checkState(params)
      if (!cs.isValid) return cs

      const { state, council } = params

      var res = await getLocalitiesForCouncil({ state, council })
      if (!res.localities) return { isValid: false }

      return {
        isValid: true,
        title: `Suburbs in ${res.council.displayNameCouncil}, ${res.state.name}`,
        items: res.localities.map(l => ({
          pathType: 'browseLocality',
          name: `${l.locationName} ${l.postcode}`,
          source: {
            locality: l,
          },
          // params: {
          //   state: l.stateUrl,
          //   council: l.councilUrl,
          //   locality: l.url,
          // },
        })),
      }
    },
  },

  // for locality
  categories: {
    queryKey: ({ state, council, locality }) => ['categories', { state, council, locality }],

    lookup: async ({ params, categories }) => {
      const cs = checkState(params)
      if (!cs.isValid) return cs

      const { state, council, locality } = params
      var res = await getLocalityByParams({ state, council, locality })
      if (!res) return { isValid: false }

      const localityParams = {
        state: res.stateUrl,
        council: res.councilUrl,
        locality: res.url,
      }

      const stateName = res.stateUrl.replaceAll('_', ' ')

      return {
        isValid: true,
        title: `Healthcare Services in ${res.name}, ${stateName}`,
        defaultItems: [
          {
            pathType: 'searchLocality',
            name: `All Health Services`,
            source: {
              locality: res,
            },
            // params: localityParams,
          },
        ],
        items: categories.map(c => ({
          pathType: 'searchLocalityCategories',
          name: c.title,
          source: {
            locality: res,
            categories: c,
          },
          // source: c,
          // params: {
          //   ...localityParams,
          //   categories: c.shortUrl,
          // },
        })),
      }
    },
  },

  categoriesRoot: {
    queryKey: () => ['categoriesRoot'],

    lookup: async ({ categories }) => ({
      title: `Healthcare Service Categories`,
      items: categories.map(c => ({
        pathType: 'browseCategory',
        name: c.title,
        source: { categories: c },
        // params: {
        //   category: c.shortUrl,
        // },
      })),
    }),
  },

  categoryInfo: {
    queryKey: ({ categories }, { searchLocation }) => [
      'categoryInfo',
      { categories, localityId: searchLocation?.id },
    ],

    lookup: async ({ categoriesRoute }) => {
      const { source } = categoriesRoute || {}
      const { categories: category } = source || {}

      // console.log('categoryInfo', { categoriesRoute, source, category })

      if (!category) return { isValid: false }

      return {
        title: category.titleWithSuffix,
        staticContent: {
          type: 'categoryDescription',
          id: category.id,
        },
        bottomItems: [
          {
            pathType: 'searchLocalityCategories',
            withLocation: true,
            templates: {
              default: `??`,
              withLocation: `Search {{ source.categories.titleWithSuffix }} in {{ source.location.summary }}`,
            },
            source,
          },
          {
            pathType: 'searchCategories',
            queryParams: { rloc: true },
            templates: {
              default: `Search {{ source.categories.titleWithSuffix }} in your location`,
              withLocation: `Search {{ source.categories.titleWithSuffix }} in Queensland`,
            },
            source,
          },
        ],
      }
    },
  },
}

const generatePath = ({ item, options }) => {
  // console.log('generatePath', { item, options })

  const { routePath } = options
  const { pathType: pathTypeName, source, params, deferRouteGeneration } = item

  const pathType = pathTypeName && routePath.pathType({ pathTypeName })

  if (!pathType) {
    console.warn('Invalid pathType', { pathTypeName, pathType })
    return null
  }

  const g = !deferRouteGeneration && routePath.generateUrl({ pathType, source, params })

  return {
    ...g,
    pathType,
    really: pathType,
  }
}

export const browseQuery = (query, options) => async () => {
  if (!query) return null

  const qres = await query.lookup(options)

  const addPathItems = items => {
    if (!_.isArray(items)) return

    const r = _.sortBy(
      items.map(item => {
        const { queryParams } = item
        const { url, params, ...gr } = generatePath({ item, options })
        // console.log({ url, params, ...gr })

        const qp = url && queryParams && Qs.stringify(queryParams)
        const to = _.compact([url, qp]).join('?')

        return { ...item, to, ...gr }
      }),
      ['name']
    )

    return r
  }

  const apkeys = ['defaultItems', 'items', 'bottomItems']

  const apadd = _.chain(qres)
    .pick(apkeys)
    .mapValues(it => addPathItems(it))
    .value()

  const res = { ...qres, ...apadd }

  // console.log({ browseQuery: res })

  return res
}
