import React from 'react'

import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { withStyles } from '@mui/styles'
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { useAnalytics } from 'use-analytics'

import { forwardRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'

const styles = theme => ({
  list: {
    width: 320,
  },
  fullList: {
    width: 'auto',
  },
  navLink: {
    color: theme.palette.text.primary,
  },
  listIcon: {
  },
  listText: {
  },
})

// TODO: Properly map functions

// eslint-disable-next-line react/display-name
const Reset = forwardRef(
  ({ children, ...itemProps }, ref) => <div ref={ref} {...itemProps}>{children}</div>
)

const MainMenu = observer(withRouter(({
  classes,
  history,
  match,
  menu: menuId,
  onToggleMenu = () => undefined,
}) => {
  const store = useStore()
  const { track } = useAnalytics()

  const { tenantConfig: tc } = useConfig()
  const menu = tc.ui?.menus?.[menuId]

  const handleReset = () => {
    store.resetAll()
    if (match !== '/') {
      history.push('/')
    }
  }

  const handleTrackMenuItem = (item) => () => {
    track('mainMenuItemClick', {
      category: 'Navigation',
      label: item.label,
    })
  }

  if (!menu?.items) return null

  const MenuItem = (item) => {
    if (item.type === 'divider') return <Divider sx={{ my: 1 }} />

    const listItemProps =
      item.route ? {
        component: NavLink,
        to: item.route,
      }
      : item.href ? {
        component: Link,
        href: item.href,
        target: '_blank',
      }
      : item.function === 'reset' ? {
        component: Reset,
        onClick: handleReset,
      }
      : null

    if (!listItemProps) return null

    return (
      <ListItem
        onClick={handleTrackMenuItem(item)}
        button
        {...listItemProps}
      >
        <ListItemIcon
          classes={{ root: classes.listIcon }}
        >
          <i className={clsx(item.icon, 'fa-fw')} />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listText }}
          primary={item.label}
        />
      </ListItem>
    )
  }

  const items = (menu.items || []).map((item, i) => <MenuItem key={i} {...item} />)

  return (
    <Box
      className={clsx(classes.list)}
      onClick={onToggleMenu(false)}
      onKeyDown={onToggleMenu(false)}
    >
      <List>
        {items}
      </List>
    </Box>
  )
}))

export default withStyles(styles, { name: 'AMSMainMenu' })(MainMenu)
