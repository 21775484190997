// import { buildPathGenerator } from 'ui/query/route-query'

export const cieQueryHelpers = {
  // pathLinkItems: (params, view) => {
  //   console.log({ view })
  //   const { config, routeConfig, state } = view || {}
  //   const { items: itemConfig } = config || {}
  //   const { itemQuery, sourceQuery, path, add } = itemConfig || {}
  //   const { depsMap } = state || {}
  //   if (!depsMap) {
  //     console.warn(`Waiting on deps`, { depsMap, state })
  //     return
  //   }
  //   const pathConfig = routeConfig?.paths[itemConfig?.path]
  //   const linkItems = depsMap?.[sourceQuery]
  //   const pathGen = buildPathGenerator({ path, itemQuery, pathConfig })
  //   const itemMapper = itemMappers[itemQuery] || itemMappers.default
  //   if (!linkItems) {
  //     console.warn(`Invalid link items`, { depsMap, linkItems, state })
  //     return
  //   }
  //   const items = linkItems
  //     .map(pathGen)
  //     .map(itemMapper)
  //     .map(item => ({ ...item, ...add }))
  //   return items
  // },
}

const itemMappers = {
  default: () => ({
    name: 'Bad Item Mapper!',
    to: '#',
  }),
  state: ({ item: c, route: r }) => ({
    name: c?.name,
    to: r?.url,
  }),
  council: ({ item: c, route: r }) => ({
    name: c?.displayNameCouncil,
    to: r?.url,
  }),
  locality: ({ item: c, route: r }) => ({
    name: c?.locationName,
    to: r?.url,
  }),
}
