// import logo from './assets/images/full_logo@0.5x.png'

import mentalHealthCommissionLogo from './assets/images/mental-health-commission-logo-white.svg'
import mapMarker from './assets/images/marker-mindcare.png'
import icon from './assets/images/logo.svg'
import bush1 from './assets/images/bush-1.svg'
import bush2 from './assets/images/bush-2.svg'
import cloud1 from './assets/images/cloud-1.svg'
import cloud2 from './assets/images/cloud-2.svg'
import cloud3 from './assets/images/cloud-3.svg'
import iconAlcohol from './assets/images/icon-alcohol-drug.svg'
import iconImmediate from './assets/images/icon-immediate-help.svg'
import iconMentalHealth from './assets/images/icon-mental-health.svg'
import iconSearch from './assets/images/icon-search.svg'
import logo from './assets/images/logo.svg'
import manGraph from './assets/images/man.svg'
import womanGraph from './assets/images/woman.svg'
import thinkingBubble from './assets/images/thinking-bubble.svg'

import shared from '../shared/assets'

const { cie, headerIcons, healthengine } = shared.defaults

import '@fontsource/nunito/300.css'
import '@fontsource/nunito/400.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import badge from './assets/images/wapha-logo-white-label.svg'

export const assets = {
  logo,
  icon,
  cie,
  headerIcons,
  mapMarker,
  healthengine,
  bush1,
  bush2,
  cloud1,
  cloud2,
  cloud3,
  iconAlcohol,
  iconImmediate,
  iconMentalHealth,
  iconSearch,
  mentalHealthCommissionLogo,
  manGraph,
  womanGraph,
  thinkingBubble,
  badges: {
    default: badge,
  },
}
