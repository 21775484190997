import _ from 'lodash'

export const asArray = val => {
  if (!val) return []
  if (Array.isArray(val)) return val
  if (typeof val === 'object' && 'slice' in val) return val.slice()
  return [val]
}

export const toEnglishList = (val, opts) => {
  const { delimiter = ', ', terminator = ' and ' } = opts || {}

  if (!Array.isArray(val)) return null
  if (val.length <= 2) return val.join(terminator)

  return [_.take(val, val.length - 1).join(delimiter), _.last(val)].join(terminator)
}

export const delay = async ms => new Promise(res => setTimeout(() => res(), ms))
