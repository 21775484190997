import _ from 'lodash'
import { types, getRoot } from 'mobx-state-tree'
import { keys, values, entries, toJS } from 'mobx'

import { Search } from './Search'
import { SearchParams } from './SearchParams'
import { SearchUI } from './SearchUI'
// import { Service } from './Service'
// import { Event } from './Event'

import { config, useConfig } from 'config/config'
import { asArray, toEnglishList } from '../utils/misc-helpers'
import { toFavourite } from '../utils/search-utils'
import { toJSDeep } from '../utils/mobx'

const { tenantConfig: tc } = config
// const { track } = useAnalytics()

export const SearchControl = types
  .model({
    // selectedResult: types.maybeNull(
    //   types
    //     .model({
    //       source: types.enumeration(['results', 'map']),
    //       // result: types.safeReference(types.union(Service, Event)),
    //       id: types.maybeNull(types.string, null),
    //     })
    //     .views(self => ({
    //       // get control() {
    //       //   return getRoot(self).search
    //       // },
    //       // get result() {
    //       //   // console.log('get result', self.id)
    //       //   if (!self.id) return null
    //       //   try {
    //       //     const searchId = self.id.match(/^([^_])*/)[0]
    //       //     const search = self.control.searches.get(searchId)
    //       //     const result = self.id in search.resultsMap ? search.resultsMap[self.id] : null
    //       //     return result
    //       //   } catch {
    //       //     console.error('get selected result error')
    //       //     return null
    //       //   }
    //       // },
    //     })),
    //   null
    // ),
    searches: types.map(Search, {}),
    searchCount: 0,
    params: types.optional(SearchParams, {}),
    ui: types.optional(SearchUI, {}),
  })

  .volatile(() => ({
    contentRegions: {},
  }))

  .views(self => ({
    get helpers() {
      return {
        _searches: { store: self._searches },
        json: ['params', 'activeCategories'],
      }
    },

    get _searches() {
      const so = _.fromPairs(entries(self.searches))
      return {
        ...so,
        helpers: _.mapValues(so, (v, k) => ({ [k]: { store: v } })),
      }
    },

    get tenant() {
      return getRoot(self).tenant
    },

    get locationSummary() {
      if (self.params.location?.type === 'locality') return self.params.location?.summary
      return 'Australia'
    },

    get categoriesSummary() {
      const sc = self.params.selectedCategoriesMeta

      if (sc.length === 1) {
        return sc[0].titleWithSuffix
      }

      return self.tenant.options.defaultCategoryTitle
    },

    get locationPath() {
      const lconfig = tc?.search?.router?.location
      if (!lconfig) return null

      const loc = self.params.location
      if (!loc) return null

      return `${lconfig.prefix}/${loc.stateUrl}/${loc.councilUrl}/${loc.url}`
    },

    get categoryPath() {
      const cconfig = tc?.search?.router?.category
      if (!cconfig) return null

      const sc = self.params.selectedCategoriesMeta
      if (sc.length === 0) return null

      const field = cconfig.field || 'title'
      const delimiter = cconfig.delimiter || '+'

      const cats = sc.map(c => c[field].replace(' ', '_')).join(delimiter)
      return `${cconfig.prefix}/${cats}`
    },

    get path() {
      const rconfig = tc?.search?.router
      if (!rconfig) return null

      const pc = [rconfig.root, self.locationPath, self.categoryPath]
        .filter(x => x)
        .join('/')
        .replace(/(\/)+/, '/')

      return pc
    },

    get welcome() {
      const title = `Welcome to ${self.tenant.options.title}`

      const l = toEnglishList(_.without(keys(self.searches), 'favourites'))
      const lt = l ? `for ${l}` : null
      const body = `To begin searching ${lt} in your region, please select a location in the search settings.`

      return { title, body }
    },

    // TODO: fix up all the consumers of theses things so everything is a []

    get activeCategories() {
      // this is the categories applicable to any given search
      // i.e. selected || visible

      const p = self.params

      if (p.selectedCategories) return p.selectedCategories
      return self.params.visibleCategories.map(c => c.id)
    },

    get activeCategoriesArray() {
      return asArray(self.activeCategories)
    },

    get activeCategoriesSet() {
      return new Set(self.activeCategoriesArray)
    },

    get contentRegion() {
      const regionId = self.params.location?.id
      return self?.contentRegions?.[regionId]?.id || 'default'
    },

    get selectedInformationCategory() {
      const tenant = getRoot(self).tenant
      if (tenant.options.categoryMode !== 'single') return undefined

      const catId = self.params.selectedCategories || 'default'
      if (!_.isString(catId)) return undefined

      return tenant.getCategory(catId)
    },

    getSelectedInformation(type = 'information') {
      const tenant = getRoot(self).tenant
      const region = self.contentRegion

      const category = self.selectedInformationCategory
      const content = tenant.getContent({ type, region, id: category?.id })

      return { category, content }
    },

    getStaticContent(id) {
      const tenant = getRoot(self).tenant
      const region = self.contentRegion

      const noContent = {
        id: null,
        region: 'default',
        title: 'Content Not Found',
        body: '',
      }

      const content = tenant.getContent({ type: 'static', region, id })

      return content || noContent
    },

    get favouritesSearch() {
      return values(self.searches).find(s => s.type === 'favourites')
    },

    isFavourite(id) {
      if (!self.favouritesSearch) return false

      const fid = id.match(/_fav$/) ? id : `${id}_fav`
      return self.favouritesSearch.hasResult(fid)
    },
  }))

  .actions(self => ({
    // default filters are volatile
    loadDefaultFilters() {
      Array.from(self.searches.values()).forEach(v => {
        const sc = tc.search?.searches[v.id]
        if (sc.defaultFilter) v.setDefaultFilter(sc.defaultFilter)
      })
    },

    // initLocation() {
    //   const { defaultLocation = null, initLocation = 'dialog' } = tc.search?.location

    //   if (lc.defaultLocation) {
    //     self.params.setDefaultLocation(lc.defaultLocation)
    //   }

    //   if (self.params.location && self.params.location.id === 0) {
    //     console.warn('bad loc')
    //     self.params.setLocation(null)
    //   }

    //   if (self.params.location) return

    //   const loc =
    //     lc && lc.initLocation === 'default' && lc.defaultLocation && !self.params.location
    //       ? lc.defaultLocation
    //       : null

    //   self.params.setLocation(loc)
    // },

    setSelected(source, result) {
      try {
        self.selectedResult = result ? { source, id: result.id } : null
      } catch {
        console.error('select error')
        self.selectedResult = null
      }
    },

    loadContentRegions(contentRegions = []) {
      self.contentRegions = contentRegions.reduce((obj, val) => {
        // TODO: dedupe
        val.regions.forEach(
          r =>
            (obj[r] = {
              id: val.id,
              title: val.title,
            })
        )
        return obj
      }, {})
    },

    clear() {
      self.selectedResult = null

      Array.from(self.searches.values()).forEach(v => v.clear())
    },

    bang() {
      console.log('bang!', self.searchCount)
    },

    initSearch() {
      // analytics.track('searchControlInit', {
      //   category: 'SearchControl',
      //   label: 'Init',
      // })

      self.selectedResult = undefined
      self.searchCount = self.searchCount + 1

      Array.from(self.searches.values()).forEach(v => v.initSearch())
    },

    loadMore({ search }) {
      // track('searchControlLoadMore', {
      //   category: 'SearchControl',
      //   label: 'Load More',
      // })

      self.selectedResult = undefined
      self.searches.get(search).loadMore()
    },

    setShowAllCategories(value) {
      self.ui.setShowAllCategories(value)

      const visible = self.params.visibleCategories.map(c => c.id)

      // single category mode deselect

      if (
        tc.options.categoryMode === 'single' &&
        !visible.includes(self.params.selectedCategories)
      ) {
        self.params.setSelectedCategories(null)
      }
    },

    loadFavourites(favourites) {
      if (!self.favouritesSearch || !favourites) return
      self.favouritesSearch.setResults(favourites)
    },

    toggleFavourite(result) {
      const r = toJS(result)
      const fs = self.favouritesSearch

      console.log({ r })

      if (!fs) {
        console.error('Favourites search not found')
        return
      }

      const fav = r.id.match(/_fav$/) ? r : toFavourite(r, 'favourites')

      if (!fs.hasResult(fav.id)) {
        fs.insert(fav)
      } else {
        fs.removeById(fav.id)
      }
    },
  }))
